import React from "react";
import styled from "styled-components";
import { Desktop, Mobile } from "../../MediaQuery/OnboardingPageMediaQuery";

const OurPromisesSection = () => {
  return (
    <Container>
      {/* Our Promises 제목과 부가 설명 */}
      <TitleWrapper>
        <Title>Our Promises</Title>
        <Subtitle>
          <Desktop>
            법(Law)의 문턱을 낮추고(low), <br />
            고객의 가장 든든한 편이 되어드리겠습니다.
          </Desktop>
          <Mobile>
            법(Law)의 문턱을 낮추고(low)
            <br />
            고객의 가장 든든한 편이
            <br /> 되어드리겠습니다.
          </Mobile>
        </Subtitle>
      </TitleWrapper>

      {/* 첫 번째 카드 */}
      <CardsWrapper>
        <Card>
          <CardTop style={{ background: "#00F370" }}>
            <CardHeader>#</CardHeader>
            <CardTitle>990원</CardTitle>
          </CardTop>
          <CardBottom>
            <CardDescription>990원의 저렴한 가격</CardDescription>
            <CardSubText>
              <Desktop>
                모두가 차별없이 공정하게 법적 보호를 받을 수 있도
                <br />
                록, 가격을 최대한 낮추었습니다.
              </Desktop>
              <Mobile>
                모두가 차별없이 공정하게 법적 보호를 받을 수<br /> 있도록,
                가격을 최대한 낮추었습니다.
              </Mobile>
            </CardSubText>
            <DividerWrapper>
              <Divider xmlns="http://www.w3.org/2000/svg">
                <line x1="0" y1="0" x2="140" y2="0" />
              </Divider>
              <VSText>VS</VSText>
              <Divider xmlns="http://www.w3.org/2000/svg">
                <line x1="0" y1="0" x2="140" y2="0" />
              </Divider>
            </DividerWrapper>
            <PriceText>
              <Desktop>
                변호사 시간 당 평균 상담 비용 180,000원 / 법원 판례 열람 비용
                1,000원
              </Desktop>
              <Mobile>
                변호사 시간 당 평균 상담 180,000원 / 법원 판례 1건 열람 1,000원
              </Mobile>
            </PriceText>
          </CardBottom>
        </Card>

        {/* 두 번째 카드 */}
        <Card>
          <CardTop style={{ background: "#ECECEC" }}>
            <CardHeader>#</CardHeader>
            <CardTitle>
              Best <br /> Dataset
            </CardTitle>
          </CardTop>
          <CardBottom>
            <CardDescription>
              판례 최다 보유, <br /> 최근 판례까지 모두 확인 가능
            </CardDescription>
            <CardSubText>
              업계 1위 수준의 판례 개수와 <br />
              가장 최신화된 데이터를 보유하고 있습니다.
            </CardSubText>
          </CardBottom>
        </Card>

        {/* 세 번째 카드 */}
        <Card>
          <CardTop style={{ background: "#3A7DFF" }}>
            <CardHeader>#</CardHeader>
            <CardTitle>
              Lawlow <NotBold> × </NotBold> AI
            </CardTitle>
          </CardTop>
          <CardBottom>
            <CardDescription>Lawlow의 독보적 AI기술</CardDescription>
            <CardSubText>
              <Desktop>
                (1) 매 검색마다 AI가 400만개 이상의 판례와 내<br /> 상황을
                실시간으로 비교분석합니다
                <br />
                (2) 판례 속 상황과 내 상황의 공통점, 차이점을 가장
                <br /> 정확하고 자세하게 보여줍니다.
              </Desktop>
              <Mobile>
                (1) 매 검색마다 AI가 400만개 이상의 판례와 내<br /> 상황을
                실시간으로 비교분석합니다
                <br />
                (2) 판례 속 상황과 내 상황의 공통점, 차이점을
                <br /> 가장 정확하고 자세하게 보여줍니다.
              </Mobile>
            </CardSubText>
          </CardBottom>
        </Card>
      </CardsWrapper>
    </Container>
  );
};
const NotBold = styled.p`
  display: inline;
  color: #000;
  font-size: 32px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  position: relative;
  bottom: 10px;

  @media (max-width: 959px) {
    bottom: 5px;
  }
`;

// 스타일링
const Container = styled.div`
  height: 714px;
  gap: 40px;
  padding: 100px 101px 130px;
  margin: 0 auto;

  // 임시
  @media (max-width: 1439px) {
    padding: 64px 24px;
    height: 1727px;
  }
  @media (max-width: 959px) {
    padding: 64px 24px;
    height: 1427px;
  }
`;

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const Title = styled.h2`
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`;

const Subtitle = styled.p`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  margin: 24px 0 80px;

  @media (max-width: 959px) {
    font-size: 24px;
    line-height: 36px;
    margin: 25px 0 55px;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  // 임시
  @media (max-width: 1439px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 959px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  width: 397px;
  height: 467px;
  flex-shrink: 0;
  border-radius: 24px;
  box-shadow: 0px 4px 30px 20px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  color: #1d1d1f;
  background: #fff; /* 카드 바탕은 흰색 */

  @media (max-width: 959px) {
    width: 312px;
    height: 355px;
  }
`;

const CardTop = styled.div`
  width: 100%;
  height: 50%;
  border-radius: 24px 24px 0 0;
  display: flex;
  flex-direction: column;
`;

const CardBottom = styled.div`
  width: 100%;
  height: 50%;
  background: #fff; /* 아랫부분은 흰색 */
  border-radius: 0 0 24px 24px;
  display: flex;
  flex-direction: column;
  color: #1d1d1f;
`;

const CardHeader = styled.div`
  color: #1d1d1f;
  font-size: 40px;
  font-weight: 200;
  line-height: 36px;
  margin-top: 32px;
  margin-left: 32px;
  margin-bottom: auto;

  @media (max-width: 959px) {
    font-size: 32px;
    margin-top: 26px;
    margin-left: 20px;
  }
`;

const CardTitle = styled.h3`
  color: #1d1d1f;
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
  margin: 27px auto 24px 32px;

  @media (max-width: 959px) {
    font-size: 48px;
    line-height: 48px;
    margin: 12px auto 28px 20px;
  }
`;

const CardDescription = styled.p`
  color: #1d1d1f;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin-top: 24px;
  margin-left: 32px;

  @media (max-width: 959px) {
    font-size: 20px;
    margin-top: 16px;
    margin-left: 20px;
  }
`;

const CardSubText = styled.p`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  margin-left: 32px;
  text-align: left;

  @media (max-width: 959px) {
    font-size: 14px;
    line-height: 23px;
    margin-left: 20px;
  }
`;

const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 32px 0;

  @media (max-width: 959px) {
    margin: 0;
  }
`;

const Divider = styled.svg`
  width: 140px;
  height: 0.3px;
  flex-shrink: 0;
  fill: rgba(0, 0, 0, 0.3);
  stroke-width: 0.3px;
  stroke: rgba(0, 0, 0, 0.6);

  @media (max-width: 959px) {
    width: 137px;
  }
`;

const VSText = styled.p`
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 18px;

  @media (max-width: 959px) {
    margin: 0 11px;
  }
`;

const PriceText = styled.p`
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  margin: 10px 28px 0;
  text-align: left;

  @media (max-width: 959px) {
    font-size: 10px;
    line-height: 18px;
    margin: 0 31px 0 20px;
  }
`;

export default OurPromisesSection;
