import React, { useEffect } from "react";
import { styled } from "styled-components";
import Spinner from "../img/loading.mp4";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import config from "../config.js";

const KakaoRedirect = () => {
  // REACT_APP_SERVER_DOMAIN_DEV
  // REACT_APP_SERVER_DOMAIN_DEPLOY

  const navigate = useNavigate();
  // const SERVER_IP = "192.168.0.4:8080"; // 배포 전
  // const SERVER_URI = process.env.REACT_APP_SERVER_DOMAIN_DEPLOY; // 배포 후
  //const SERVER_URI = process.env.REACT_APP_SERVER_DOMAIN_DEV;
  const SERVER_URI = config.serverUri;
  const REDIRECT_SERVER_URI = `${SERVER_URI}/login/kakao`;

  useEffect(() => {
    const loginInfo = Cookies.get("lawlow"); // 로그인 정보가 있다면 메인 페이지로 리디렉트
    if (loginInfo) {
      navigate("/main");
    } else {
      if (window.location.pathname === "/oauth/kakao") {
        // 우리가 지정해둔(카카오 앱에 등록해둔) Redirect URI 로 이동해서 카카오로부터 인가코드를 받아온다
        handleOAuthRedirect();
      }
    }
  }, []);

  // 서버측에 인가 코드 전달
  function handleOAuthRedirect() {
    const code = new URL(document.location.toString()).searchParams.get("code"); // 인가 코드를 받은 후

    // 서버에 전달한다
    axios
      .get(`${REDIRECT_SERVER_URI}?code=${code}`, { withCredentials: true })
      .then(() => {
        // response 비어있어도 됨
        navigate("/main"); // 로그인 성공시 메인 페이지로 이동
      });
  }

  return (
    <Wrapper>
      <ResponsiveVideo autoPlay loop muted playsInline>
        <source src={Spinner} type="video/mp4" />
      </ResponsiveVideo>
      <Text>
        <Highlight>로그인하는중</Highlight>
        <br />
        잠시만 기다려주세요
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-weight: 500;
  overflow: hidden; /* 스크롤 막기 */
`;

const Text = styled.div`
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 60px;

  @media (max-width: 599px) {
    font-size: 14px;
    line-height: 32px;
  }
`;

const Highlight = styled.span`
  font-size: 48px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);

  @media (max-width: 599px) {
    font-size: 24px;
    line-height: 23px;
  }
`;

const ResponsiveVideo = styled.video`
  width: 320px;
  height: 320px;

  @media (max-width: 599px) {
    width: 150px;
    height: 150px;
  }
`;

export default KakaoRedirect;
