import React from "react";
import styled from "styled-components";
import { Desktop, Mobile } from "../../MediaQuery/OnboardingPageMediaQuery";

const WhyDoesItMatterSection = () => {
  return (
    <Container>
      <Header>Why does it matter?</Header>
      <Title>
        <Desktop>판례가 무엇인가요? 정말 도움이 되나요?</Desktop>
        <Mobile>
          판례가 무엇인가요? <br />
          정말 도움이 되나요?
        </Mobile>
      </Title>
      <Line />

      <QuestionContainer>
        <Question>
          <NumberCircle xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <circle cx="16" cy="16" r="16" fill="black" />
            <text
              x="16"
              y="16"
              fill="white"
              font-size="14"
              font-weight="bold"
              text-anchor="middle"
              alignment-baseline="central">
              1
            </text>
          </NumberCircle>
          <QuestionText>판례가 무엇인가요?</QuestionText>
        </Question>
        <AnswerText>
          판례는 법원이 특정 문제상황에 대해 내린 법적 결정이나 판단을
          의미합니다. 판례는 이후 유사한 문제상황에서 법적 기준으로 사용될 수
          있기 때문에, 중요한 참고자료가 됩니다. 변호사를 선임하게 되면,
          변호사는 보통 가장 먼저 판례를 찾는데 많은 시간을 사용합니다.
        </AnswerText>
      </QuestionContainer>

      <Line />

      <QuestionContainer>
        <Question>
          <NumberCircle xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <circle cx="16" cy="16" r="16" fill="black" />
            <text
              x="16"
              y="16"
              fill="white"
              font-size="14"
              font-weight="bold"
              text-anchor="middle"
              alignment-baseline="central">
              2
            </text>
          </NumberCircle>
          <QuestionText>판례를 알면 어떻게 도움이 되나요?</QuestionText>
        </Question>
        <AnswerText>
          판례의 2가지 특징을 바탕으로,
          <StrongText>
            ‘내가 보호받을 수 있는 방법’을 정확히 알 수 있습니다.
          </StrongText>
          <br />
          (1)
          <StrongText>
            {" "}
            구체적인 묘사
            <br />
          </StrongText>
          <Desktop>: </Desktop>
          판례는 문제상황을 매우 디테일하게, 상상 이상으로 자세하게 묘사합니다.
          때문에 나의 상황과 판례 속 상황의 공통점, 차이점을 정확하게 비교할 수
          있습니다.
          <br />
          (2)
          <StrongText>
            {" "}
            높은 공신력
            <br />
          </StrongText>
          <Desktop>
            : 판례는 유사한 문제상황에서 법적 기준으로 사용되는 중요한
            참고자료입니다. <br />: 때문에 "내 상황에 대해 법이 어떤 결론을
            내릴지, 판례를 예측할 수 있습니다."
          </Desktop>
          <Mobile>
            판례는 유사한 문제상황에서 법적 기준으로 사용되는, 중요한
            참고자료입니다. 때문에 “나의 상황"에 대해 법원이 어떤 결론을 내릴지,
            판례로 예측할 수 있습니다.
          </Mobile>
        </AnswerText>
      </QuestionContainer>

      <Line />

      <QuestionContainer>
        <Question>
          <NumberCircle xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <circle cx="16" cy="16" r="16" fill="black" />
            <text
              x="16"
              y="16"
              fill="white"
              font-size="14"
              font-weight="bold"
              text-anchor="middle"
              alignment-baseline="central">
              3
            </text>
          </NumberCircle>
          <QuestionText>
            <Desktop>
              변호사, 판사 아닌 일반인도 판례를 이해할 수 있나요?
            </Desktop>
            <Mobile>법 모르는 일반인도 판례를 이해할 수 있나요? </Mobile>
          </QuestionText>
        </Question>
        <AnswerText>
          네. 오직 Lawlow에서 누구나 이해할 수 있는, 일반인을 위한 판례를
          제공받을 수 있습니다.
        </AnswerText>
      </QuestionContainer>
    </Container>
  );
};

// 스타일링
const Container = styled.div`
  padding: 100px 101px 110px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1439px) {
    padding: 80px 64px;
  }

  @media (max-width: 959px) {
    padding: 64px 24px;
  }
`;

const Header = styled.h2`
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin: 0;
`;

const Title = styled.h1`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  margin: 0;
  padding: 24px 0 80px;

  @media (max-width: 959px) {
    font-size: 24px;
    line-height: 36px;
    padding: 25px 0 44px;
  }
`;

const Line = styled.div`
  width: 100%; /* 화면 가로에 꽉 채움 */
  max-width: 1238px; /* 최대 너비 설정 */
  height: 0.01rem; /* 선 두께 */
  background-color: rgba(0, 0, 0, 0.3); /* 회색 밑줄 색상 */
  flex-shrink: 0;
  display: block;

  @media (max-width: 1439px) {
    width: 100%; /* 반응형 - 작은 화면에서도 꽉 채움 */
  }
`;

const QuestionContainer = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 959px) {
    flex-direction: column;
  }
`;

const Question = styled.div`
  display: flex;
  margin-bottom: 12px;
  text-align: left;
  max-width: 458px;

  @media (max-width: 1439px) {
    max-width: 188px;
  }

  @media (max-width: 959px) {
    max-width: 458px;
  }
`;

const NumberCircle = styled.svg`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 16px;

  @media (max-width: 959px) {
    width: 24px; /* 모바일에서 원 크기 줄이기 */
    height: 24px; /* 모바일에서 원 크기 줄이기 */
    margin-top: 4px;
    margin-right: 12px;
  }
`;

const QuestionText = styled.p`
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  margin: 0;

  @media (max-width: 959px) {
    font-size: 16px;
  }
`;

const AnswerText = styled.p`
  width: 633px;
  margin: 0;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;

  @media (max-width: 959px) {
    font-size: 13px;
    line-height: 23px;
    width: auto;
  }
`;

const StrongText = styled.span`
  font-weight: 700;
`;

export default WhyDoesItMatterSection;
