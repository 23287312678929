import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Desktop, Mobile } from "../../MediaQuery/OnboardingPageMediaQuery";
import config from "../../config.js";

const StatisticsSection = () => {
  const [statistics, setStatistics] = useState({
    casesCount: 0, // 보유 판례수
    acceptedAnswerCount: 0, // 네이버 지식인 답변수
    acceptRate: 0, // 네이버 지식인 채택률
  });

  useEffect(() => {
    const SERVER_URI = config.serverUri;
    const uri = "/counts";
    const url = `${SERVER_URI}${uri}`;

    const fetchStatistics = async () => {
      try {
        const response = await fetch(url, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch statistics");
        }

        const data = await response.json();

        setStatistics({
          casesCount: data.casesCount,
          acceptedAnswerCount: data.acceptedAnswerCount,
          acceptRate: data.acceptRate,
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchStatistics();
  }, []);

  // 천 단위 콤마 추가 함수
  const formatNumber = (num) => {
    return num.toLocaleString(); // toLocaleString()을 사용하여 천 단위로 콤마 추가
  };

  return (
    <Container>
      <StatisticsContainer>
        {/* 제목과 부제목 */}
        <Title>
          <Desktop>
            430만 법률 사건 중 나와 비슷한
            <br /> 피해사례가 있다면 얼마나 힘이 될까요?
          </Desktop>
          <Mobile>
            430만 법률 사건 중<br /> 나와 비슷한 피해사례가 있다면
            <br /> 얼마나 힘이 될까요?
          </Mobile>
        </Title>
        <SubTitle>전국 모든 사건의 판결문을 Lawlow에서 찾아보세요.</SubTitle>

        {/* 통계 항목들 */}
        <Statistics>
          <StatItem>
            <LeftStatDescription>Lawlow는</LeftStatDescription>
            <StatValue type="type1">
              {formatNumber(statistics.casesCount)}
            </StatValue>
            <RightStatDescription>
              개의 판례를 보유하고 있어요.
            </RightStatDescription>
          </StatItem>

          <StatItem>
            <LeftStatDescription>N사 지O인에서</LeftStatDescription>
            <StatValue type="type2">
              {formatNumber(statistics.acceptedAnswerCount)}
            </StatValue>
            <RightStatDescription>명에게 도움을 주었고,</RightStatDescription>
          </StatItem>

          <StatItem>
            <LeftStatDescription>전체 답변 중</LeftStatDescription>
            <StatValue type="type3">{statistics.acceptRate}%</StatValue>
            <RightStatDescription>가 채택되었어요!</RightStatDescription>
          </StatItem>
        </Statistics>
      </StatisticsContainer>
    </Container>
  );
};

// 스타일링
const Container = styled.div`
  height: 752px;
  margin: 0 auto;
  text-align: center;
`;

const StatisticsContainer = styled.div`
  margin: 100px 192px 124px;

  @media (max-width: 1439px) {
    margin: 64px 48px;
  }

  @media (max-width: 959px) {
    margin: 64px 28px;
  }
`;

const Title = styled.h1`
  color: #000;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  margin: 0;
  margin-bottom: 12px;

  @media (max-width: 959px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

const SubTitle = styled.p`
  color: #626262;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
  margin: 0;

  @media (max-width: 959px) {
    font-size: 14px;
  }
`;

const Statistics = styled.div`
  display: flex;
  flex-direction: column; /* 세로 정렬로 변경 */
  align-items: center;
  gap: 30px;
  margin-top: 74px;

  @media (max-width: 959px) {
    margin-top: 32px;
  }
`;

const StatItem = styled.div`
  width: 798px;
  display: flex;
  flex-direction: row; /* 가로 정렬로 변경 */
  justify-content: space-between;

  @media (max-width: 959px) {
    width: auto;
    flex-direction: column;
    align-items: center;
  }
`;

const StatValue = styled.p`
  font-size: 64px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  /* 모바일 공통 코드 */
  @media (max-width: 959px) {
    font-size: 44px;
    height: 46px;
  }

  /* 각 StatValue 별 배경색과 너비 설정 */
  ${(props) =>
    props.type === "type1" &&
    `
    background: rgba(0, 0, 0, 0.08);
    width: 320px;
    @media (max-width: 959px) {
      width: 242px;  /* 모바일에서 가로폭 100%로 설정 */
    }
  `}

  ${(props) =>
    props.type === "type2" &&
    `
    background: rgba(0, 243, 112, 0.10);
    width: 184px;
    @media (max-width: 959px) {
      width: 148px;  /* 모바일에서 가로폭 100%로 설정 */
    }
  `}

  ${(props) =>
    props.type === "type3" &&
    `
    background: rgba(0, 87, 255, 0.10);
    width: 164px;

    @media (max-width: 959px) {
      width: 114px;  /* 모바일에서 가로폭 100%로 설정 */
    }
  `}
`;

const LeftStatDescription = styled.p`
  width: 120px;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-align: center;

  @media (max-width: 959px) {
    width: auto;
    font-size: 13px;
    line-height: 23px;
  }
`;

const RightStatDescription = styled.p`
  width: 205px;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 959px) {
    font-size: 13px;
    line-height: 23px;
  }
`;

export default StatisticsSection;
