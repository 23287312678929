import { useMediaQuery } from "react-responsive";

// 질문결과페이지 미디어쿼리
// max-width, min-width 둘 다포함
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1141, maxWidth: 10000 });
  return isDesktop ? children : null;
};

const LargeTablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 745, maxWidth: 1140 });
  return isTablet ? children : null;
};

const SmallTablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 744 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  return isMobile ? children : null;
};

export { Desktop, LargeTablet, SmallTablet, Mobile };

/*
Large Desktop / 10000-1141
Small Desktop, Large Tablet / 1140-745
Medium, Small Tablet / 744-481
Mobile / 480-320
*/

//  /* Large Desktops / 1920-1141 */
// @media (max-width: 1920px) and (min-width: 1141px) {
//   }

// /*Small Desktop, Large Tablet / 1140-745 */
// @media (max-width: 1140px) and (min-width: 745px) {
// }

// /* Medium, Small Tablet / 744-481 */
// @media (max-width: 744px) and (min-width: 481px) {
// }

// /* Mobile / 480-320 */
// @media (max-width: 480px) and (min-width: 320px) {
// }
