import React from "react";
import styled from "styled-components";
import legal_info_button from "../../img/legal_info_button.png";
import { Desktop, Mobile } from "../../MediaQuery/OnboardingPageMediaQuery.js";

const ReviewSection = () => {
  // 버튼 클릭시 온보딩 페이지의 맨 위로 스르륵 부드럽게 올라옴
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Container>
      <Title>Fact Check & 5/5 Review</Title>
      <Subtitle>
        <Desktop>
          법률 지식이 전혀 없어도 우리의 권리를 <br />
          당당하게 주장할 수 있다는 사실, 알고 계셨나요?
        </Desktop>
        <Mobile>
          법률 지식이 전혀 없어도 <br />
          우리 권리를 당당하게 주장할 수 <br />
          있다는 사실, 알고 계셨나요?
        </Mobile>
      </Subtitle>
      <ReviewContainer>
        <ReviewCard>
          <TextIcon>
            <ReviewText>
              친구에게 빌려준 돈 못받을 거라 <br /> 생각했는데, Lawlow 덕분에
              <br />
              변호인 없이도 받을 수 있었어요
            </ReviewText>
            {/* SVG 아이콘 추가 */}
            <Icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="13"
                viewBox="0 0 19 13"
                fill="none">
                <path
                  d="M11.8382 1.26356C12.4591 0.631369 13.1504 0.275763 13.9124 0.196738C14.6743 0.0913734 15.4221 0.196738 16.1558 0.512834C16.8896 0.828929 17.4963 1.31624 17.976 1.97477C18.484 2.6333 18.8085 3.42354 18.9496 4.34548C19.0907 5.26743 18.9355 6.25522 18.484 7.30887C18.0042 8.38886 17.1435 9.38983 15.9019 10.3118C14.6884 11.2074 13.221 11.8264 11.4996 12.1688C11.1892 12.2215 10.9775 12.2347 10.8646 12.2083C10.7518 12.182 10.6812 12.1425 10.653 12.0898C10.653 11.8527 10.7376 11.6815 10.907 11.5762C11.6407 11.2074 12.2333 10.7464 12.6848 10.1932C13.1645 9.61373 13.4609 9.07373 13.5737 8.57325C13.6866 8.04643 13.5596 7.66448 13.1928 7.42741C13.0799 7.34838 12.8118 7.13765 12.3885 6.79522C11.9652 6.42644 11.5701 5.96547 11.2033 5.4123C10.8364 4.85914 10.653 4.22695 10.653 3.51573C10.6812 2.80452 11.0763 2.0538 11.8382 1.26356ZM1.18523 1.26356C1.80607 0.631369 2.49745 0.275763 3.25939 0.196738C4.02132 0.0913734 4.76915 0.196738 5.50286 0.512834C6.23658 0.828929 6.84331 1.31624 7.32304 1.97477C7.831 2.6333 8.15553 3.42354 8.29663 4.34548C8.43773 5.26743 8.28252 6.25522 7.831 7.30887C7.35126 8.38886 6.49056 9.38983 5.24889 10.3118C4.03543 11.2074 2.568 11.8264 0.846594 12.1688C0.536176 12.2215 0.324528 12.2347 0.211648 12.2083C0.0987691 12.182 0.0282197 12.1425 0 12.0898C0 11.8527 0.0846592 11.6815 0.253978 11.5762C0.987693 11.2074 1.58031 10.7464 2.03183 10.1932C2.51156 9.61373 2.80787 9.07373 2.92075 8.57325C3.03363 8.04643 2.90664 7.66448 2.53978 7.42741C2.4269 7.34838 2.15882 7.13765 1.73552 6.79522C1.31222 6.42644 0.917144 5.96547 0.550286 5.4123C0.183429 4.85914 0 4.22695 0 3.51573C0.0282197 2.80452 0.423297 2.0538 1.18523 1.26356Z"
                  fill="#00DF67"
                />
              </svg>
            </Icon>
          </TextIcon>
          <ReviewDescription>
            지인에게 100만원을 빌려줬다가 계속 받지 못하고 있<br />
            었어요. 저도 급히 돈쓸 일이 생겨서 이제는 정말 대안
            <br />이 필요했는데, 제 상황에 맞는 판례를 찾아준다는
            <br /> Lawlow를 추천 받았습니다.{" "}
            <StrongText>
              제가 겪은 피해사항을{" "}
              <Desktop>
                구<br />
                구절절입력했더니, 정말 세심하게 딱 맞는 판례를 찾<br />
                아주더라고요. 덕분에 채무 이행을 강하게 요구할 수 <br />
                있었어요.
              </Desktop>
              <Mobile>
                구구절절 입력했더니, 정말 세심하게 딱 맞는 판례를
                <br />찾 아주더라고요. 덕분에 채무 이행을 강하게 요구할 수
                있었어요.
              </Mobile>
            </StrongText>
          </ReviewDescription>
        </ReviewCard>
        <ReviewCard>
          <TextIcon>
            <ReviewText>
              직장 내 성희롱 문제, 어디에 물<br />
              어볼지 고민이 많았는데, 이때
              <br />
              Lawlow가 도움이 많이 됐어요
            </ReviewText>
            {/* SVG 아이콘 추가 */}
            <Icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="13"
                viewBox="0 0 19 13"
                fill="none">
                <path
                  d="M11.8382 1.26356C12.4591 0.631369 13.1504 0.275763 13.9124 0.196738C14.6743 0.0913734 15.4221 0.196738 16.1558 0.512834C16.8896 0.828929 17.4963 1.31624 17.976 1.97477C18.484 2.6333 18.8085 3.42354 18.9496 4.34548C19.0907 5.26743 18.9355 6.25522 18.484 7.30887C18.0042 8.38886 17.1435 9.38983 15.9019 10.3118C14.6884 11.2074 13.221 11.8264 11.4996 12.1688C11.1892 12.2215 10.9775 12.2347 10.8646 12.2083C10.7518 12.182 10.6812 12.1425 10.653 12.0898C10.653 11.8527 10.7376 11.6815 10.907 11.5762C11.6407 11.2074 12.2333 10.7464 12.6848 10.1932C13.1645 9.61373 13.4609 9.07373 13.5737 8.57325C13.6866 8.04643 13.5596 7.66448 13.1928 7.42741C13.0799 7.34838 12.8118 7.13765 12.3885 6.79522C11.9652 6.42644 11.5701 5.96547 11.2033 5.4123C10.8364 4.85914 10.653 4.22695 10.653 3.51573C10.6812 2.80452 11.0763 2.0538 11.8382 1.26356ZM1.18523 1.26356C1.80607 0.631369 2.49745 0.275763 3.25939 0.196738C4.02132 0.0913734 4.76915 0.196738 5.50286 0.512834C6.23658 0.828929 6.84331 1.31624 7.32304 1.97477C7.831 2.6333 8.15553 3.42354 8.29663 4.34548C8.43773 5.26743 8.28252 6.25522 7.831 7.30887C7.35126 8.38886 6.49056 9.38983 5.24889 10.3118C4.03543 11.2074 2.568 11.8264 0.846594 12.1688C0.536176 12.2215 0.324528 12.2347 0.211648 12.2083C0.0987691 12.182 0.0282197 12.1425 0 12.0898C0 11.8527 0.0846592 11.6815 0.253978 11.5762C0.987693 11.2074 1.58031 10.7464 2.03183 10.1932C2.51156 9.61373 2.80787 9.07373 2.92075 8.57325C3.03363 8.04643 2.90664 7.66448 2.53978 7.42741C2.4269 7.34838 2.15882 7.13765 1.73552 6.79522C1.31222 6.42644 0.917144 5.96547 0.550286 5.4123C0.183429 4.85914 0 4.22695 0 3.51573C0.0282197 2.80452 0.423297 2.0538 1.18523 1.26356Z"
                  fill="#000"
                />
              </svg>
            </Icon>
          </TextIcon>
          <ReviewDescription>
            직장에서 꽤 오랫동안 상사한테서 은근한 성추행을 당<br />
            해왔어요. 이런 일을 어디에 물어봐야 할지 모르겠고
            <br /> 경찰서에 가자니 조금 무서웠는데,{" "}
            <StrongText>
              Lawlow에서 검색
              <br />한 판례를 쭉 읽어보니 가해자의 행동이 법에 어떻게
              <br /> 걸리는지 자세하게 알 수 있었어요.{" "}
            </StrongText>
            덕분에 용기를 얻<br />고 문제 해결에 좀 더 적극적으로 나설 수
            있었어요. <br />
            사적인 고민이 있다면 Lawlow 완전 추천해요!
          </ReviewDescription>
        </ReviewCard>
        <ReviewCard>
          <TextIcon>
            <ReviewText>
              집주인에게 전세보증금을
              <br /> 못 받을 뻔 했는데, Lawlow에서 <br />
              검색했던 판례를 말씀드리니
              <br /> 아무 반박도 못하시더라고요
            </ReviewText>
            {/* SVG 아이콘 추가 */}
            <Icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="13"
                viewBox="0 0 19 13"
                fill="none">
                <path
                  d="M11.8382 1.26356C12.4591 0.631369 13.1504 0.275763 13.9124 0.196738C14.6743 0.0913734 15.4221 0.196738 16.1558 0.512834C16.8896 0.828929 17.4963 1.31624 17.976 1.97477C18.484 2.6333 18.8085 3.42354 18.9496 4.34548C19.0907 5.26743 18.9355 6.25522 18.484 7.30887C18.0042 8.38886 17.1435 9.38983 15.9019 10.3118C14.6884 11.2074 13.221 11.8264 11.4996 12.1688C11.1892 12.2215 10.9775 12.2347 10.8646 12.2083C10.7518 12.182 10.6812 12.1425 10.653 12.0898C10.653 11.8527 10.7376 11.6815 10.907 11.5762C11.6407 11.2074 12.2333 10.7464 12.6848 10.1932C13.1645 9.61373 13.4609 9.07373 13.5737 8.57325C13.6866 8.04643 13.5596 7.66448 13.1928 7.42741C13.0799 7.34838 12.8118 7.13765 12.3885 6.79522C11.9652 6.42644 11.5701 5.96547 11.2033 5.4123C10.8364 4.85914 10.653 4.22695 10.653 3.51573C10.6812 2.80452 11.0763 2.0538 11.8382 1.26356ZM1.18523 1.26356C1.80607 0.631369 2.49745 0.275763 3.25939 0.196738C4.02132 0.0913734 4.76915 0.196738 5.50286 0.512834C6.23658 0.828929 6.84331 1.31624 7.32304 1.97477C7.831 2.6333 8.15553 3.42354 8.29663 4.34548C8.43773 5.26743 8.28252 6.25522 7.831 7.30887C7.35126 8.38886 6.49056 9.38983 5.24889 10.3118C4.03543 11.2074 2.568 11.8264 0.846594 12.1688C0.536176 12.2215 0.324528 12.2347 0.211648 12.2083C0.0987691 12.182 0.0282197 12.1425 0 12.0898C0 11.8527 0.0846592 11.6815 0.253978 11.5762C0.987693 11.2074 1.58031 10.7464 2.03183 10.1932C2.51156 9.61373 2.80787 9.07373 2.92075 8.57325C3.03363 8.04643 2.90664 7.66448 2.53978 7.42741C2.4269 7.34838 2.15882 7.13765 1.73552 6.79522C1.31222 6.42644 0.917144 5.96547 0.550286 5.4123C0.183429 4.85914 0 4.22695 0 3.51573C0.0282197 2.80452 0.423297 2.0538 1.18523 1.26356Z"
                  fill="#3A7DFF"
                />
              </svg>
            </Icon>
          </TextIcon>
          <ReviewDescription>
            얼마 전 이사 준비 중에, 집주인이 시세가 떨어졌다며
            <br /> “전세 보증금을 나눠서 돌려주겠다”고 하셨어요. <br />
            ‘이게 전세사기인가?’ 싶었지만,{" "}
            <StrongText>
              Lawlow 덕분에{" "}
              <Desktop>
                전세
                <br />
                보증금
              </Desktop>
              <Mobile>
                전 <br />세 보증금
              </Mobile>
              미반환에 대한 형량을 이미 알고 가서
              <Desktop>
                잘 대처
                <br />
              </Desktop>
              <Mobile>
                잘 대<br />처
              </Mobile>
              할 수 있었어요.
            </StrongText>{" "}
            신혼이라 보증금 반환이 정말
            <Desktop>
              중요했
              <br />
              는데,
            </Desktop>
            <Mobile>
              중요 <br />했 는데,
            </Mobile>
            전부 다 받았습니다! 감사합니다 :)
          </ReviewDescription>
        </ReviewCard>
      </ReviewContainer>
      <Dots>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="48"
          viewBox="0 0 6 48"
          fill="none">
          <circle cx="3" cy="3" r="3" fill="#7CD8CA" fill-opacity="0.2" />
          <circle cx="3" cy="17" r="3" fill="#7CD8CA" fill-opacity="0.5" />
          <circle cx="3" cy="31" r="3" fill="#7CD8CA" />
          <circle cx="3" cy="45" r="3" fill="#66C5B8" />
        </svg>
      </Dots>
      {/* 버튼 */}
      <ButtonBase onClick={scrollToTop} />
    </Container>
  );
};

// 스타일링
const Container = styled.div`
  margin: 0 auto;
  padding-top: 100px;
  text-align: center;
  height: 984px; /* padding top값으로 인해 -100한 값으로 984px로 설정 */
  background: #f9f9f9;

  // 임시
  @media (max-width: 1439px) {
    height: 1841px;
  }

  @media (max-width: 959px) {
    height: 1354px;
    padding: 64px 24px;
    position: relative; // Dots의 position absolute사용을 위함
  }
`;

const Title = styled.h1`
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 24px;

  @media (max-width: 959px) {
    font-size: 13px;
    margin-bottom: 25px;
  }
`;

const Subtitle = styled.p`
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 40px;

  @media (max-width: 959px) {
    flex-direction: column;
    font-size: 24px;
    line-height: 36px;
  }
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  //margin: 0 auto;

  // @media (max-width: 959px) {
  @media (max-width: 1439px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ReviewCard = styled.div`
  width: 397px;
  height: 427px;
  flex-shrink: 0;
  background-color: #f8f8f8;
  border-radius: 24px;
  box-shadow: 0px 4px 30px 20px rgba(0, 0, 0, 0.03);
  max-width: 800px;
  text-align: left;

  @media (max-width: 959px) {
    width: 312px;
    height: 326px;
  }
`;

const TextIcon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;
  padding: 27.73px 32px 0 32px;

  @media (max-width: 959px) {
    padding: 32px 20px 0 20px;
  }
`;

const ReviewText = styled.h3`
  margin-bottom: 10px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 150% */
  margin: 0;

  @media (max-width: 959px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

const Icon = styled.div`
  width: 19px;
  height: 12.064px;
  flex-shrink: 0;
  fill: #00df67;
`;

const Dots = styled.div`
  width: 6px;
  height: 48px;
  position: absolute;
  margin-top: 50px;
  left: 50%; /* 수평 중앙 */

  @media (max-width: 1439px) {
    position: absolute;
    //top: 85%; /* 수직 중앙 */
    left: 50%; /* 수평 중앙 */
  }

  @media (max-width: 959px) {
    width: 4px;
    height: 34px;
    position: absolute;
    margin-top: 32px;
    //top: 85%; /* 수직 중앙 */
    left: 50%; /* 수평 중앙 */
    //transform: translate(-50%, -50%); /* 정확한 중앙 정렬을 위한 transform */
  }
`;

const ReviewDescription = styled.p`
  color: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  padding: 22.27px 32px 32px;
  margin: 0;

  @media (max-width: 959px) {
    font-size: 13px;
    line-height: 22px;
    padding: 20px 25px 32px 20px;
  }
`;

const StrongText = styled.span`
  color: #000;
  font-weight: 500;
  line-height: 22px;

  @media (max-width: 959px) {
    font-size: 13px;
  }
`;

const ButtonBase = styled.div`
  position: absolute;
  margin-top: 146px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; /* 추가: z-index 설정 */
  background: url(${legal_info_button}) no-repeat center center;
  background-size: contain;
  cursor: pointer;
  width: 399px;
  height: 64px;

  @media (max-width: 1439px) {
    margin-top: 134px;
  }

  @media (max-width: 959px) {
    margin-top: 98px;
    width: 309px;
    height: 51px;
  }
`;

export default ReviewSection;
