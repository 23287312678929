import React from "react";
import ModalBasic from "./ModalBasic.jsx";
import styled from "styled-components";
import config from "../../config.js";
import { useNavigate, useLocation } from "react-router-dom";
import useStore from "../../store.js";

// ModalBasic 컴포넌트를 세분화한 삭제 재확인 컴포넌트
const ModalDeleteCheck = ({ isOpen, onClose, logContent, logId }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { logs, setLogs, setShowDeleteHistorySnackbar } = useStore.getState(); // Zustand에서 상태와 set 함수 가져오기

  // 현재 보고 있는 페이지의 queryId를 URL에서 가져오기
  const currentQueryId = Number(location.pathname.split("/").pop());
  // 스낵바는 Mobile에만 우선 적용하기 위해 위해 현재 화면의 가로 크기 가져오기
  const screenWidth = window.innerWidth;

  const deleteLog = async () => {
    const SERVER_URI = config.serverUri;
    const uri = `/private/logs/${logId}`;
    const url = `${SERVER_URI}${uri}`;

    if (!logId) {
      console.error("logId가 정의되지 않았습니다");
      return;
    }

    try {
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // 로그 삭제 후 logs 업데이트
      const updatedLogs = logs.filter((log) => log.id !== logId);
      setLogs(updatedLogs); // logs 상태 업데이트 -> 자동으로 재렌더링

      // 화면 해상도가 1140px 이하일 때(Mobile, Tablet) 스낵바 사용
      if (screenWidth <= 1140) {
        setShowDeleteHistorySnackbar(true);
        // 3초 후 애니메이션이 시작되고, 애니메이션이 완료된 후 사라지게 설정
        setTimeout(() => {
          setShowDeleteHistorySnackbar(false);
        }, 6000); // 3초 유지 + 3초 동안 애니메이션 실행 = 총 6초 후 상태 변경
      } else {
        // 해상도가 1140px 보다 클 때(Desktop) window.alert 사용
        onClose(); // 모달창을 닫고
        window.alert("질문 내역이 삭제되었습니다");
      }

      // 삭제 후의 logs가 빈 배열인지 확인
      // 로그가 빈 배열일 경우 알림 후 메인으로 리디렉트
      if (updatedLogs.length === 0) {
        window.alert("질문 내역이 더이상 없습니다. 페이지를 이동합니다");
        navigate("/main");
        return;
      }

      // Case1 - 현재 보고 있는 페이지의 질문을 삭제한 경우
      if (logId === currentQueryId) {
        // 최신 질문으로 이동 (logId보다 큰 질문이 있는지 확인)
        const newerLog = updatedLogs.find((log) => log.id > logId);
        if (newerLog) {
          navigate(`/main/q/${newerLog.id}`);
        } else {
          // 최신 질문이 없으면 가장 가까이있는 과거의 질문으로 이동
          const closestOlderLog = [...updatedLogs]
            .reverse() // 배열을 역순으로 정렬
            .find((log) => log.id < logId); // logId보다 작은 첫 번째 로그를 찾음
          if (closestOlderLog) {
            navigate(`/main/q/${closestOlderLog.id}`);
          }
        }
      } else {
        // Case2 - 현재 보고 있는 페이지가 아닌, 다른 질문을 삭제한 경우
        // 로그 내역 업데이트만 하기(재렌더링)
        setLogs(updatedLogs);
      }
    } catch (error) {
      console.error("로그 삭제 중 오류 발생:", error);
    }
  };

  return (
    <>
      <ModalBasic
        isOpen={isOpen}
        onClose={onClose}
        width={window.innerWidth > 430 ? "400px" : "312px"}
        height="fit-content">
        <ContentWrapper>
          <TextWrapper>
            <Header>정말 삭제할까요?</Header>
            <Content>{logContent}</Content>
          </TextWrapper>
          <ButtonWrapper>
            <ButtonStyles type="cancel" onClick={onClose}>
              취소
            </ButtonStyles>
            <ButtonStyles
              type="confirm"
              onClick={deleteLog}
              style={{ color: "#00F370" }}>
              삭제
            </ButtonStyles>
          </ButtonWrapper>
        </ContentWrapper>
      </ModalBasic>
    </>
  );
};

const ContentWrapper = styled.div`
  padding: 32px;

  @media (max-width: 430px) {
    padding: 24px;
  }
`;

const Header = styled.div`
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline;

  @media (max-width: 430px) {
    font-size: 16px;
    font-weight: 700;
  }
`;

const Content = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 16px 0px;

  /* 텍스트를 최대 3줄까지만 표시 */
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  @media (max-width: 430px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

const TextWrapper = styled.div`
  width: 336px;
  height: auto; /* 텍스트 내용에 따라 크기 변화 */

  @media (max-width: 430px) {
    width: 261px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ButtonStyles = styled.button`
  margin-left: 8px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 6px;
  background: ${(props) => (props.type === "cancel" ? "#F2F2F2" : "#000")};
  color: ${(props) => (props.type === "cancel" ? "#000" : "#FFF")};

  width: 70px;
  height: 40px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;

  &:disabled {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.3);
    cursor: not-allowed;
  }

  @media (max-width: 430px) {
    font-size: 14px;
  }
`;

export default ModalDeleteCheck;
