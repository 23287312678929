import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Spinner from "../img/loading.mp4";
import HeightInterval from "../Components/HeightInterval.jsx";
import DataLoader from "./ProgressBar/DataLoader";
import useStore from "../store";

const Loading = () => {
  const [isInitializing, setIsInitializing] = useState(true); // 초기화 상태
  const { setRefreshDetected, progress, setProgress } = useStore();

  useEffect(() => {
    /* 로딩 화면 스크롤 막기 */
    // 컴포넌트가 마운트될 때 스크롤 비활성화
    document.body.style.overflow = "hidden";
    // 컴포넌트가 언마운트될 때 스크롤 활성화
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  useEffect(() => {
    let interval; // useEffect 스코프에서 선언

    const fetchData = async () => {
      setIsInitializing(true); // 초기화 시작 // 초기화 변수를 설정하여 DataLoader 에 넘길 progress 값 정하기
      let currentProgress = progress; // 초기화
      setProgress(0); // 진행률 초기화

      // 3초 대기 // setProgress(0)가 될 텀을 둘 수 있도록 프라미스로 지연
      await new Promise((resolve) => setTimeout(resolve, 3000));

      setIsInitializing(false); // 초기화 완료

      interval = setInterval(() => {
        if (currentProgress < 80) {
          currentProgress += 80 / 80; // 10초 동안 80%까지 증가
        } else if (currentProgress < 90) {
          currentProgress += 10 / 80; // 10초 동안 90%까지 증가
        } else if (currentProgress < 95) {
          currentProgress += 5 / 80; // 10초 동안 95%까지 증가
        } else if (currentProgress < 99) {
          currentProgress += 4 / 240; // 30초 동안 99%까지 점진적으로 증가
        } else {
          clearInterval(interval);
        }
        setProgress(Math.min(100, Math.round(currentProgress * 100) / 100));
      }, 125); // 125ms마다 업데이트

      await new Promise((resolve) => setTimeout(resolve, 60000)); // 1분 동안 데이터 받아오기
      setProgress(99); // 99% 까지 차오르기
    };

    fetchData();

    // 새로고침 시도시 실행되는 함수
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // 기본 알림을 표시하지 않기 위해 빈 문자열 반환
      setRefreshDetected(true); // 새로고침 시도시 store의 상태 업데이트
    };

    // addEventListener 먼저 실행됨
    window.addEventListener("beforeunload", handleBeforeUnload);

    // 정리 작업
    return () => {
      clearInterval(interval); // setInterval 정리 // 없으면 페이지 이동 후에도 setInterval이 끊임없이 실행됨
      window.removeEventListener("beforeunload", handleBeforeUnload); // 이벤트 리스너 정리
    };
  }, []);

  return (
    <Wrapper>
      <ResponsiveVideo autoPlay loop muted playsInline>
        <source src={Spinner} type="video/mp4" />
      </ResponsiveVideo>
      <Text>
        결과를 불러오기까지
        <br />
        1분 가량 소요될 수 있습니다
      </Text>
      <HeightInterval height={4} />
      <DataLoader progress={isInitializing ? 0 : progress} />
      {/* progress를 전달 */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-weight: 500;
  overflow: hidden; /* 스크롤 막기 */
`;

const Text = styled.div`
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;

  @media (max-width: 599px) {
    font-size: 14px;
    line-height: 23px;
  }
`;

const ResponsiveVideo = styled.video`
  width: 150px;
  height: 150px;

  @media (max-width: 599px) {
    width: 120px;
    height: 120px;
  }
`;

export default Loading;
