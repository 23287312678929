import React from "react";
import styled from "styled-components";
import { Desktop, Mobile } from "../../MediaQuery/OnboardingPageMediaQuery";
import firstFaceIcon from "../../img/onb-page/first-face-onb.svg";
import secondFaceIcon from "../../img/onb-page/second-face-onb.svg";
import thirdFaceIcon from "../../img/onb-page/third-face-onb.svg";
import firstSentence from "../../img/onb-page/fist-sentence.png";
import secondSentence from "../../img/onb-page/second-sentence.png";
import thirdSentence from "../../img/onb-page/third-sentence.png";
import fourthSentence from "../../img/onb-page/fourth-sentence.png";
import fifthSentence from "../../img/onb-page/fifth-sentence.png";

const WhoAreWeSection = () => {
  return (
    <Container>
      <WhoAreWeContainer>
        <Header>Who we are?</Header>
        <Title>
          <Desktop>
            문제가 생겼을 때 당황해서 해결방법이 생각나지 않는
            <br /> 사람들을 위한 판례 검색 서비스, Lawlow
          </Desktop>
          <Mobile>
            문제가 생겼을 때 당황해서 <br /> 해결방법이 생각나지 않는 <br />
            사람들을 위한 판례 검색 서비스
          </Mobile>
        </Title>
        <CardContainer>
          <Card>
            <IconText>
              <Icon src={firstFaceIcon} alt="first face icon" />
              <CardText>
                억울한 상황이 생기면 머리가 새하얘지고
                <br /> 어떻게 해야할지 모르겠어요.
              </CardText>
            </IconText>
          </Card>
          <Card>
            <IconText>
              <Icon src={secondFaceIcon} alt="second face icon" />
              <CardText>
                상대방이 분명 잘못했다고는 건 알겠는데, <br />
                뭐가, 왜 잘못된건지 설명하기 힘들어요.
              </CardText>
            </IconText>
          </Card>
          <Card>
            <IconText>
              <Icon src={thirdFaceIcon} alt="third face icon" />
              <CardText>
                경찰서에 신고했더니 변호사를 선임해야 <br />
                한다네요. 비용 부담에 막막하기만 해요
              </CardText>
              <CardSubText>*변호사 시간 당 평균 상담 비용 18만원</CardSubText>
            </IconText>
          </Card>
        </CardContainer>
        <Description>
          <Sentence src={firstSentence} alt="third face icon" />
          <Divider xmlns="http://www.w3.org/2000/svg">
            <line x1="110" y1="0" x2="1350" y2="4.224" />
          </Divider>
          <Sentence src={secondSentence} alt="third face icon" />
          <Divider xmlns="http://www.w3.org/2000/svg">
            <line x1="140" y1="0" x2="1100.046" y2="3.958" />
          </Divider>
          <Sentence src={thirdSentence} alt="third face icon" />
          <Divider xmlns="http://www.w3.org/2000/svg">
            <line x1="90" y1="0" x2="1133.543" y2="4.294" />
          </Divider>
          <Sentence src={fourthSentence} alt="third face icon" />
          <Divider xmlns="http://www.w3.org/2000/svg">
            <line x1="130" y1="0" x2="1098.81" y2="3.958" />
          </Divider>
          <Sentence src={fifthSentence} alt="third face icon" />
          <Divider xmlns="http://www.w3.org/2000/svg">
            <line x1="0" y1="0" x2="1227.64" y2="3.958" />
          </Divider>
        </Description>
        <DotDotDot>
          .<br />.<br />.
        </DotDotDot>
      </WhoAreWeContainer>
    </Container>
  );
};

// 스타일링
const Container = styled.div`
  height: 1361px;
  text-align: center;
  background: linear-gradient(
    180deg,
    rgba(0, 243, 112, 0.1) 0%,
    rgba(58, 125, 255, 0.1) 100%
  );

  // 임시
  @media (max-width: 1439px) {
    height: 1541px;
  }

  @media (max-width: 959px) {
    height: 1041px;
  }
`;

const WhoAreWeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 99px 101px 130px 101px;

  @media (max-width: 959px) {
    padding: 64px 24px;
  }
`;

const Header = styled.h2`
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  color: #000;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  margin: 0;
  margin-bottom: 80px;

  @media (max-width: 959px) {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 48px;
    width: 304px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  padding: 0 4px;

  @media (max-width: 1439px) {
    //@media (max-width: 959px) {
    gap: 16px;
    flex-direction: column;
  }
`;

const Card = styled.div`
  width: 397px;
  height: 324px;
  background: #fff;
  border-radius: 24px;
  box-shadow: 0px 4px 30px 20px rgba(0, 0, 0, 0.03);
  flex: 1;
  text-align: left;

  @media (max-width: 959px) {
    width: auto;
    // height: 220px;
  }
`;

const IconText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 21px 0 27px;

  @media (max-width: 959px) {
    // padding: 30px 0 28px; // 임시 // 마지막 카드만 아래 padding값 다르게 적용해야함
    padding: 0;
    width: 312px;
    height: 220px; //임시 // 마지막 카드만 244px
  }
`;

const Icon = styled.img`
  width: 213px;
  height: 160px;
  margin-bottom: 20px;
  object-fit: cover; /* 이미지가 영역을 꽉 채우도록 확대 */
  object-position: center; /* 이미지가 가운데로 배치되도록 설정 */

  @media (max-width: 959px) {
    width: auto;
    height: 140px;
    margin-bottom: 0px;
  }
`;

const Sentence = styled.img`
  width: auto;
  height: 30px;

  @media (max-width: 959px) {
  }
`;

const CardText = styled.p`
  color: #303030;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
  margin-bottom: 20.84px;

  @media (max-width: 959px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 10px; // 임시
  }
`;

const CardSubText = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

const Description = styled.div`
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1439px) {
    //@media (max-width: 959px) {
    display: none;
  }
`;

const DotDotDot = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  margin-top: 45.99px;

  @media (max-width: 959px) {
    display: none;
  }
`;

const Divider = styled.svg`
  width: 1227.64px;
  height: 3.958px;
  flex-shrink: 0;
  fill: rgba(0, 0, 0, 0.3);
  stroke-width: 0.3px;
  stroke: rgba(0, 0, 0, 0.3);
  margin-top: 6px;
  margin-bottom: 40px;
`;

export default WhoAreWeSection;
