import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SituationBox from "./SituationBox.jsx";
import copy from "../img/copy.png";
import ResultForm from "../Components/ResultForm.jsx";
import ResultFormMobile from "../Components/ResultFormMobile.jsx";
import HeightInterval from "../Components/HeightInterval.jsx";
import Alert from "../Components/Alert.jsx";
import config from "../config.js";
import {
  Desktop,
  LargeTablet,
  SmallTablet,
  Mobile,
} from "../MediaQuery/QueryPageMediaQuery.js";
import useStore from "../store.js";
import ReAskForm from "./ReAskForm.jsx";

/* Result는 Desktop, Mobile을 동시에 만들고, 
ResultForm을 두 버전 따로 만듦 */
const Result = ({ id }) => {
  const navigate = useNavigate();
  const queryId = id;

  const [inquiries, setInquiries] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const { situation, setSituation, setShowCopyCaseSnackbar } = useStore();
  /* ReAskForm의 높이를 설정하기 위함 */
  const [situationBoxHeight, setSituationBoxHeight] = useState(0);
  const situationBoxRef = useRef(null);

  // 스낵바는 Mobile에만 우선 적용하기 위해 위해 현재 화면의 가로 크기 가져오기
  const screenWidth = window.innerWidth;

  // 렌더링 완료 후, Ref를 통해 SituationBox DOM 요소에 접근하여 높이를 설정
  useEffect(() => {
    if (situationBoxRef.current) {
      setSituationBoxHeight(situationBoxRef.current.offsetHeight); // SituationBox 높이를 가져옴
      // 추후 LAWLOW-249 에러 처리
      // console.log("setSituationBoxHeight 으로 설정");
    }
    // console.log("변경 후 id", id);
    // console.log("situationBoxRef.current", situationBoxRef.current);
    // console.log(
    //   "situationBoxRef.current.offsetHeight",
    //   situationBoxRef.current.offsetHeight
    // );
    // Ref가 설정된 후 혹은 질문 결과 id과 inquiries 달라지면 실행
  }, [id, inquiries]);

  useEffect(() => {
    // REACT_APP_SERVER_DOMAIN_DEV
    // REACT_APP_SERVER_DOMAIN_DEPLOY

    if (!queryId) return; // id가 없다면 실행하지 않음
    // const SERVER_URI = process.env.REACT_APP_SERVER_DOMAIN_DEPLOY;
    const SERVER_URI = config.serverUri;
    const uri = `/private/logs/${queryId}`;
    const url = `${SERVER_URI}${uri}`;

    const fetchLog = async () => {
      try {
        const response = await fetch(url, {
          credentials: "include", // 요청에 인증 정보 포함
        });

        if (!response) {
          throw new Error("Failed to fetch Result");
        }
        if (response.status === 400 || response.status === 500) {
          // URL 변조 방지하여 질문 내용 임의 접근 막기
          alert("잘못된 접근입니다. 페이지를 이동합니다");
          navigate("/main");
        }

        const data = await response.json();
        sortInquiriesByRelevance(data); // 데이터 정렬 후 상태 변경
        setSituation(data[0].content); // 질문한 내용
      } catch (error) {
        console.error("Error catching Result Error:", error);
      }
    };

    fetchLog();
  }, [queryId]);

  function sortInquiriesByRelevance(data) {
    const sortedData = data.sort((a, b) => b.relevance - a.relevance);
    setInquiries(sortedData); // 정렬된 데이터로 상태 업데이트
  }

  /* SituationBox에서 버튼이 눌렸을 때 처리 */
  const handleCopyClick = () => {
    const textToCopy = `${situation}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        // 화면 해상도가 1140px 이하일 때(Mobile, Tablet) 스낵바 사용
        if (screenWidth <= 1140) {
          setShowCopyCaseSnackbar(true);
          // 3초 후 애니메이션이 시작되고, 애니메이션이 완료된 후 사라지게 설정
          setTimeout(() => {
            setShowCopyCaseSnackbar(false);
          }, 6000); // 3초 유지 + 3초 동안 애니메이션 실행 = 총 6초 후 상태 변경
        } else {
          // 해상도가 1140px 보다 클 때(Desktop) window.alert 사용
          alert("판례 내용이 복사되었습니다");
        }
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleRewriteClick = () => {
    setIsEditing(true);
  };

  // URL이 변경될 때 isEditing 상태를 false로 초기화
  useEffect(() => {
    setIsEditing(false);
  }, [id]);

  return (
    <>
      <Desktop>
        <Wrapper>
          <HeightInterval height={36} />

          {isEditing ? (
            <ReAskForm
              height={situationBoxHeight}
              initialTextValue={
                inquiries.length > 0 ? inquiries[0].content : ""
              }
              onClose={() => setIsEditing(false)}
            />
          ) : (
            <SituationBox
              ref={situationBoxRef}
              title="내 상황"
              titleFontSize={16}
              intervalHeight={10}
              contents={inquiries.length > 0 ? inquiries[0].content : ""}
              type="result"
              onCopyClick={handleCopyClick}
              onRewriteClick={handleRewriteClick}
            />
          )}

          <HeightInterval height={32} />
          <Alert />
          <HeightInterval height={40} />

          {inquiries.map((inquiry, index) => (
            <>
              <ResultForm
                key={index}
                index={index}
                courtName={inquiry.courtName}
                sentencingData={inquiry.sentencingData}
                caseNumber={inquiry.caseNumber}
                caption={inquiry.caption}
                relevance={inquiry.relevance}
                question={inquiry.question}
                detail={inquiry.detail}
                answer={inquiry.answer}
                caseId={inquiry.id}
                isComparison={inquiry.isComparison}
                commonality={inquiry.commonality}
                difference={inquiry.difference}
              />
              <HeightInterval height={40} />
            </>
          ))}
        </Wrapper>
      </Desktop>

      <LargeTablet>
        <Wrapper>
          {isEditing ? (
            <ReAskForm
              height={situationBoxHeight}
              initialTextValue={
                inquiries.length > 0 ? inquiries[0].content : ""
              }
              onClose={() => setIsEditing(false)}
            />
          ) : (
            <SituationBox
              ref={situationBoxRef}
              title="내 상황"
              titleFontSize={16}
              intervalHeight={10}
              contents={inquiries.length > 0 ? inquiries[0].content : ""}
              type="result"
              onCopyClick={handleCopyClick}
              onRewriteClick={handleRewriteClick}
            />
          )}

          <HeightInterval height={32} />
          <Alert />
          <HeightInterval height={40} />

          {inquiries.map((inquiry, index) => (
            <>
              <ResultForm
                key={index}
                index={index}
                courtName={inquiry.courtName}
                sentencingData={inquiry.sentencingData}
                caseNumber={inquiry.caseNumber}
                caption={inquiry.caption}
                relevance={inquiry.relevance}
                question={inquiry.question}
                detail={inquiry.detail}
                answer={inquiry.answer}
                caseId={inquiry.id}
                isComparison={inquiry.isComparison}
                commonality={inquiry.commonality}
                difference={inquiry.difference}
              />
              <HeightInterval height={40} />
            </>
          ))}
        </Wrapper>
      </LargeTablet>

      <SmallTablet>
        <Wrapper>
          {isEditing ? (
            <ReAskForm
              height={situationBoxHeight}
              initialTextValue={
                inquiries.length > 0 ? inquiries[0].content : ""
              }
              onClose={() => setIsEditing(false)}
            />
          ) : (
            <SituationBox
              ref={situationBoxRef}
              title="내 상황"
              titleFontSize={16}
              intervalHeight={10}
              contents={inquiries.length > 0 ? inquiries[0].content : ""}
              type="result"
              onCopyClick={handleCopyClick}
              onRewriteClick={handleRewriteClick}
            />
          )}

          <HeightInterval height={32} />
          <Alert />
          <HeightInterval height={40} />

          {inquiries.map((inquiry, index) => (
            <>
              <ResultFormMobile
                key={index}
                index={index}
                courtName={inquiry.courtName}
                sentencingData={inquiry.sentencingData}
                caseNumber={inquiry.caseNumber}
                caption={inquiry.caption}
                relevance={inquiry.relevance}
                question={inquiry.question}
                detail={inquiry.detail}
                answer={inquiry.answer}
                caseId={inquiry.id}
                isComparison={inquiry.isComparison}
                commonality={inquiry.commonality}
                difference={inquiry.difference}
              />
              <HeightInterval height={20} />
            </>
          ))}
        </Wrapper>
      </SmallTablet>

      <Mobile>
        <Alert />
        <HeightInterval height={24} />
        <Wrapper>
          {inquiries.map((inquiry, index) => (
            <>
              <ResultFormMobile
                key={index}
                index={index}
                courtName={inquiry.courtName}
                sentencingData={inquiry.sentencingData}
                caseNumber={inquiry.caseNumber}
                caption={inquiry.caption}
                relevance={inquiry.relevance}
                question={inquiry.question}
                detail={inquiry.detail}
                answer={inquiry.answer}
                caseId={inquiry.id}
                isComparison={inquiry.isComparison}
                commonality={inquiry.commonality}
                difference={inquiry.difference}
              />
              <HeightInterval height={20} />
            </>
          ))}
        </Wrapper>
      </Mobile>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default Result;
