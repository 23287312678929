import React, { useState, useEffect } from "react";
import styled from "styled-components";

// 온보딩 플로팅 버튼 컴포넌트
const FloatingButton = () => {
  const [isVisible, setIsVisible] = useState(true); // 버튼의 보임/숨김 상태 관리

  // 스크롤 이벤트 리스너
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsVisible(false); // 스크롤하면 버튼 숨기기
    } else {
      setIsVisible(true); // 맨 위로 가면 버튼 보이기
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll); // 스크롤 이벤트 추가
    return () => {
      window.removeEventListener("scroll", handleScroll); // 컴포넌트 언마운트 시 이벤트 리무브
    };
  }, []);

  return (
    <FloatingButtonContainer isVisible={isVisible}>
      <Button>
        <ArrowSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 14">
          <path
            d="M4.5 0.75V13.25M4.5 13.25L1 9.75M4.5 13.25L8 9.75"
            stroke="black"
          />
        </ArrowSvg>
        <ButtonText>Lawlow가 신뢰받는 이유를 알고 싶다면?</ButtonText>
        <ArrowSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 14">
          <path
            d="M4.5 0.75V13.25M4.5 13.25L1 9.75M4.5 13.25L8 9.75"
            stroke="black"
          />
        </ArrowSvg>
      </Button>
      {isVisible && <ScrollText>아래로 스크롤하세요!</ScrollText>}
    </FloatingButtonContainer>
  );
};

// 스타일링
const FloatingButtonContainer = styled.div`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  position: fixed;
  bottom: 20px; // 화면 아랫단으로부터 20px 떨어지게 설정 // 임의값 설정
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
`;

const Button = styled.button`
  display: inline-flex;
  height: 48px;
  padding: 18px 24px;
  align-items: center;
  gap: 11px;
  flex-shrink: 0;
  border-radius: 24px;
  border: 0.5px solid var(--main-gradient, #1ae276);
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

  /* 그라데이션 */
  // gradientBorder 안 쓰고 일부러 custom함 - deg, %
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit; /* 부모의 border-radius를 상속받음 */
    padding: 0.02rem; /* 테두리 두께 */
    background: linear-gradient(
      90deg,
      #1ae276 0%,
      #1ae276 50%,
      #0057ff 50%,
      #0057ff 100%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none; /* 클릭 이벤트를 받지 않도록 설정 */
  }
`;

const ArrowSvg = styled.svg`
  width: 7px;
  height: 12.5px;
  stroke-width: 1px;
  stroke: #000;
`;

const ButtonText = styled.span`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
`;

const ScrollText = styled.p`
  text-align: center;
  color: #999;
  font-family: Pretendard;
  font-size: 14px;
  margin-top: 10px;
`;

export default FloatingButton;
