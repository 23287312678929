import React from "react";
import styled, { keyframes } from "styled-components";

// 스낵바
const SnackbarComponent = ({ visible, src, top, alt = "스낵바 알림" }) => {
  return visible ? <SnackBar src={src} top={top} alt={alt} /> : null;
};

// 점차 사라지도록 애니메이션 설정
const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const SnackBar = styled.img`
  position: fixed; /* 스크롤 위치에 영향을 받지 않고 뷰포트(사용자가 보고 있는 화면)을 기준으로 위치를 고정 */
  top: ${({ top }) => `${top}px`}; /* 전달된 top 값을 사용 */
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: auto;
  animation: ${fadeOut} 3s ease-out 3s; /* 애니메이션을 3초 후에 시작하고, 3초 동안 사라지게 함 */
  z-index: 200; /* 모바일 질문내역 BannerMobile 컴포넌트 요소보다 위에 표시되도록 */
`;

export default SnackbarComponent;
