import React from "react";
import styled from "styled-components";
import legal_info_button from "../../img/legal_info_button.png";
import { Desktop, Mobile } from "../../MediaQuery/OnboardingPageMediaQuery.js";

const ProblemSolvingSection = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Section>
      {/* 첫 번째 문장 */}
      <Title>
        <Desktop>
          Lawlow에서 나의 문제를 해결해 줄 <br />
          ‘판례’를 지금 바로 검색해보세요.
        </Desktop>
        <Mobile>
          Lawlow에서 나의 문제를
          <br /> 해결해 줄 ‘판례’를 지금 바로
          <br /> 검색해보세요.
        </Mobile>
      </Title>

      {/* 두 번째 문장 */}
      <SubTitle>
        <Desktop>
          문제 해결에 더이상 많은 고민의 시간을 보내지 마세요. 문제는 Lawlow에게
          맡기고, 평화로운 일상을 되찾으세요.
        </Desktop>
        <Mobile>
          문제 해결에 더이상 많은 고민의 시간을 보내지 마세요. <br /> 문제는
          Lawlow에게 맡기고, 평화로운 일상을 되찾으세요.
        </Mobile>
      </SubTitle>

      {/* 버튼 */}
      <ButtonBase onClick={scrollToTop} />
    </Section>
  );
};

// 스타일링
const Section = styled.div`
  background: #f9f9f9;
  height: 486px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative; /* 부모 요소에 relative 추가 */

  @media (max-width: 959px) {
    height: 402px;
  }
`;

const Title = styled.h2`
  color: #000;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  margin: 0;
  margin-top: 100px;

  @media (max-width: 959px) {
    font-size: 24px;
    line-height: 36px;
    margin-top: 67px;
  }
`;

const SubTitle = styled.p`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
  margin-top: 20px;

  @media (max-width: 959px) {
    font-size: 13px;
    line-height: 22px;
    width: 308px;
  }
`;

const ButtonBase = styled.div`
  // position: absolute;
  margin-top: 24px;
  left: 50%;
  // transform: translateX(-50%);
  z-index: 2; /* 추가: z-index 설정 */
  background: url(${legal_info_button}) no-repeat center center;
  background-size: contain;
  cursor: pointer;
  width: 399px;
  height: 64px;

  @media (max-width: 959px) {
    margin-top: 30px;
    width: 309px;
    height: 51px;
  }
`;

export default ProblemSolvingSection;
