import { create } from "zustand";
import config from "../src/config.js";

const SERVER_URI = config.serverUri;

const useStore = create((set, get) => ({
  // temporaryUserInfo: {
  //   createdAt: "2024-04-12T07:30:56Z",
  //   creditAmount: 100,
  //   deletedAt: {
  //     time: "string",
  //     valid: true,
  //   },
  //   email: "test_lawlow.korea@gmail.com",
  //   id: 0,
  //   isDeleted: true,
  //   nickname: "사용자",
  //   oauthInfo: "string",
  //   updatedAt: "string",
  // },

  // 질문중 새로고침을 시도했었는지 여부 저장
  // 로컬 스토리지에 상태 동기화(영구 저장)하여 새로고침을 해도 상태값이 초기화되지 않도록 조치함
  isRefreshDetected: false,
  setRefreshDetected: (value) => {
    localStorage.setItem("isRefreshDetected", JSON.stringify(value));
    set({ isRefreshDetected: value });
  },
  initializeStore: () => {
    const isRefreshDetected = JSON.parse(
      localStorage.getItem("isRefreshDetected")
    );
    if (isRefreshDetected !== null) {
      set({ isRefreshDetected });
    }
  },

  // 로딩 퍼센트값 실시간 저장
  progress: 0,
  setProgress: (value) => set({ progress: value }),

  // 사용자 정보
  userInfo: null,
  setUserInfo: (value) => set({ userInfo: value }),

  // 사용자 로우 사용/충전 내역
  userLawHistory: [],
  setUserLawHistory: (value) => set({ userLawHistory: value }),

  // 사용자가 입력한 상황
  situation: false,
  setSituation: (value) => set({ situation: value }),

  // 로딩 화면을 띄울지
  loading: false,
  setLoading: (value) => set({ loading: value }),

  // 사용자가 질문한 내용
  textValue: "",
  setTextValue: (value) => set({ textValue: value }),

  // 질문 내역
  logs: [],
  setLogs: (value) => set({ logs: value }),

  // 질문 시도 여부 상태 및 액션
  // 로딩값의 초기화를 위해 질문을 한 번이라도 했을 경우 true로 바꾼다
  hasAttemptedQuestion: false,
  setHasAttemptedQuestion: () => {
    console.log("store.js에서 setHasAttemptedQuestion 호출됨");
    set({ hasAttemptedQuestion: true });
  },

  // 질문 히스토리 삭제 스낵바
  showDeleteHistorySnackbar: false,
  setShowDeleteHistorySnackbar: (value) =>
    set({ showDeleteHistorySnackbar: value }),

  // 복사 완료 스낵바
  showCopyCaseSnackbar: false,
  setShowCopyCaseSnackbar: (value) => set({ showCopyCaseSnackbar: value }),

  // 특정 key의 쿠키 삭제
  deleteCookieByKey: (key) => {
    const cookies = document.cookie.split("; ");
    cookies.forEach((cookie) => {
      const [cookieKey] = cookie.split("=");
      if (cookieKey === key) {
        document.cookie = `${key}=; Max-Age=0; path=/; domain=${window.location.hostname}`;
      }
    });
  },

  // 기본 fetch함수
  fetchPostRequest: (uri, data) => {
    return fetch(`${SERVER_URI}${uri}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
        return null;
      });
  },

  // 사용자 정보 업데이트 함수
  updateUserInfo: async (setShowUnauthorizedModal) => {
    const uri = `/private/users/info`;
    try {
      const response = await fetch(`${SERVER_URI}${uri}`, {
        credentials: "include",
      });
      if (!response.ok) {
        if (response.status === 401) {
          setShowUnauthorizedModal(true); // Unauthorized User임을 알리는 모달 창 열기
          return; // 모달 창을 열기 위해 여기서 반환
        }
        throw new Error(
          `Failed to fetch user info from store : ${response.statusText}`
        );
      }
      const data = await response.json();
      set({ userInfo: data }); // info로 요청했던 사용자 정보
    } catch (error) {
      console.error("Failed to fetch user info from store :", error);
    }
  },

  // 사용자 로우 내역 업데이트 함수
  updateUserLaw: async () => {
    const uri = `/private/users/credit`;
    try {
      const response = await fetch(`${SERVER_URI}${uri}`, {
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error(
          `Failed to fetch user Law from store : ${response.statusText}`
        );
      }
      const data = await response.json();
      set({ userLawHistory: data });
    } catch (error) {
      console.error("Failed to fetch user Law 내역 from store :", error);
    }
  },
  // 사용자 로우 차감 함수 (옵티미스틱 업데이트 포함)
  deductUserLawOptimistic: (amount) => {
    const userInfo = get().userInfo;

    if (!userInfo) return;

    // 옵티미스틱 업데이트: 로컬 상태를 먼저 업데이트
    const previousLaw = userInfo.creditAmount;
    // console.log("이전 law 개수 : ", previousLaw);
    set({
      userInfo: { ...userInfo, creditAmount: userInfo.creditAmount - amount },
    });
    // console.log("감소 후 law 개수 : ", userInfo.creditAmount);
    return { previousLaw }; // 이전 로우 값을 반환하여 복원 가능하게 함
  },

  // 서버 응답 실패시 사용자 로우 이전 값으로 원상 복귀
  restoreUserLaw: (previousLaw) => {
    const userInfo = get().userInfo;
    // console.log("실패시 현재 law 개수 : ", userInfo.creditAmount);
    // console.log("실패시 돌려놔야하는 이전 law 개수 : ", previousLaw);
    if (userInfo) {
      set({ userInfo: { ...userInfo, creditAmount: previousLaw } });
    }
    // console.log("실패시 놓은 law 개수 : ", userInfo.creditAmount);
  },

  // 질문 내역 요청 함수
  fetchLogs: async () => {
    const uri = `/private/logs`;
    try {
      const response = await fetch(`${SERVER_URI}${uri}`, {
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch logs: ${response.statusText}`);
      }

      const data = await response.json();
      set({ logs: data }); // 받아온 데이터로 logs 상태 업데이트
    } catch (error) {
      console.error("Error fetching logs from store:", error);
    }
  },
}));

export default useStore;
