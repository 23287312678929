import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ModalInquiry from "./Modal/ModalInquiry.jsx";
import ModalTermsOfService from "../Components/Modal/ModalTermsOfService.jsx";
import LogList from "../Components/LogList.jsx";
import plus from "../img/plus.png";
import inquiry from "../img/inquiry.png";
import terms from "../img/terms.png";

const LogBar = () => {
  /* 이용약관 모달창 */
  const [isTermsModalOpen, setTermsModalIsOpen] = useState(false);
  const handleOpenTermsModal = () => {
    setTermsModalIsOpen(true);
  };
  const handleCloseTermsModal = () => {
    setTermsModalIsOpen(false);
  };

  /* 문의하기 모달창 */
  const [isInquiryModalOpen, setInquiryModalIsOpen] = useState(false);
  const handleOpenInquiryModal = () => {
    setInquiryModalIsOpen(true);
  };
  const handleCloseInquiryModal = () => {
    setInquiryModalIsOpen(false);
  };

  const navigate = useNavigate();
  const moveToMain = () => {
    navigate("/main");
  };

  return (
    <Wrapper>
      <NewChat>
        <CustomButton
          image_src={plus}
          alt_text="새로운 상황"
          button_text="새로운 상황"
          width="100%"
          height="50px"
          imgWidth={32}
          imgHeight={32}
          gap={8}
          onClick={() => moveToMain()}>
          <ButtonImg
            src={plus}
            alt="새로운 상황"
            imgWidth={32}
            imgHeight={32}
          />
          <ButtonText>새로운 상황</ButtonText>
        </CustomButton>
      </NewChat>
      <LogList />
      <InquiryTermsBox>
        <ModalInquiry
          isOpen={isInquiryModalOpen}
          onCloseInquiry={handleCloseInquiryModal}
        />
        <CustomButton
          image_src={inquiry}
          alt_text="문의하기"
          button_text="문의하기"
          fontSize="16px"
          fontWeight="600"
          width="100%"
          height="44px"
          lineHeight="26px"
          onClick={() => handleOpenInquiryModal(true)}
          imgWidth={32}
          imgHeight={32}
          gap={8}>
          <ButtonImg
            src={inquiry}
            alt="문의하기"
            imgWidth={32}
            imgHeight={32}
          />
          <ButtonText>문의하기</ButtonText>
        </CustomButton>
        <ModalTermsOfService
          isOpen={isTermsModalOpen}
          onClose={handleCloseTermsModal}
        />
        <CustomButton
          image_src={terms}
          alt_text="이용약관"
          button_text="이용약관"
          fontSize="16px"
          fontWeight="600"
          width="100%"
          height="44px"
          lineHeight="26px"
          onClick={() => handleOpenTermsModal(true)}
          imgWidth={32}
          imgHeight={32}
          gap={8}>
          <ButtonImg src={terms} alt="이용약관" imgWidth={32} imgHeight={32} />
          <ButtonText>이용약관</ButtonText>
        </CustomButton>
      </InquiryTermsBox>
    </Wrapper>
  );
};

const logBarMinWidth = "312px"; // 고정

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${logBarMinWidth};
  height: 100%;
  position: fixed;
  background-color: #f9f9f9;
  /* 자식요소들 동일하게 좌우 패딩 설정 */
`;

const NewChat = styled.div`
  height: auto;
  width: auto;
  padding: 16px 32px 53px 32px; /* height가 padding 합해서 119px로 고정 */
  border: none; /* 테두리 스타일 제거 */
`;

const InquiryTermsBox = styled.div`
  // padding: 24px 32px;
  // padding: 24px 32px 29px;
  padding: 24px 32px 0px 32px;
  // margin-bottom: 64px;
  display: flex;
  flex-direction: column;
`;

const CustomButton = styled.button`
  background-color: transparent;
  width: ${(props) => (props.width === "auto" ? "auto" : `${props.width}`)};
  height: ${(props) => (props.height === "auto" ? "auto" : `${props.height}`)};
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: ${(props) => (props.gap !== undefined ? `${props.gap}px` : "0px")};
  cursor: pointer;
  padding: 0px; // 기본 설정된 padding값 삭제

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const ButtonImg = styled.img`
  width: ${(props) =>
    props.imgWidth === "auto" ? "auto" : `${props.imgWidth}px`};
  height: ${(props) =>
    props.imgHeight === "auto" ? "auto" : `${props.imgHeight}px`};
  flex-shrink: 0;
`;

const ButtonText = styled.span`
  font-family: Pretendard;
  font-style: normal;
  color: ${(props) => props.fontColor || "rgba(0, 0, 0, 0.6)"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "600"};
  line-height: ${(props) => props.lineHeight || "26px"};
`;

export default LogBar;
