import React, { useState, useEffect } from "react";
import styles from "./OnboardingPage.module.css";
import {
  Desktop,
  LargeTablet,
  SmallTablet,
  Mobile,
} from "../MediaQuery/OnboardingPageMediaQuery.js";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Logo from "../Components/Logo.jsx";
import Text from "../Components/Text.jsx";
import desktop_login_bg from "../img/desktop_gradient_login_bg.png";
// import law_img from "../img/law-img.png";
import Video_PC from "../mp4/pc_onboarding_video.mp4";
import Video_Mobile from "../mp4/mobile_onboarding_video.mp4";
import kakao from "../img/kakaoLoginButton.png";
import google from "../img/googleLoginButton.png";
import naver from "../img/naverLoginButton.png";
import m_kakao from "../img/m_kakao.png";
import m_google from "../img/m_google.png";
import m_naver from "../img/m_naver.png";
import mobile_login_bg from "../img/mobile_gradient_bg.png";
import homeIcon from "../img/homeIcon.png";
import shareIcon from "../img/shareIcon.png";
import underline from "../img/underline.png";
import pc_expanded_onboarding_img from "../img/expanded_onboarding_img_pc_tablet.png";
import mobile_expanded_onboarding_img from "../img/expanded_onboarding_img_mobile.png";
import legal_info_button from "../img/legal_info_button.png";
import freeTrialBadge from "../img/freeTrialBadge.png";
import ModalTermsOfService from "../Components/Modal/ModalTermsOfService.jsx";
import ModalPrivacyPolicy from "../Components/Modal/ModalPrivacyPolicy.jsx";
import WidthInterval from "../Components/WidthInterval.jsx";
import HeightInterval from "../Components/HeightInterval.jsx";
import ModalAddToHomeScreenPrompt from "../Components/MobilePWA/ModalAddToHomeScreenPrompt";
import ModalIOSAddToHomeScreen from "../Components/Modal/ModalIOSAddToHomeScreen.jsx";
import Stop from "../Components/Stop";
import { Helmet } from "react-helmet-async";
import StatisticsSection from "./OnboardingPageComponents/StatisticsSection.jsx";
import ReviewSection from "./OnboardingPageComponents/ReviewSection.jsx";
import WhoAreWeSection from "./OnboardingPageComponents/WhoAreWeSection.jsx";
import WhyDoesItMatterSection from "./OnboardingPageComponents/WhyDoesItMatterSection.jsx";
import WhatDoWeProvideSection from "./OnboardingPageComponents/WhatDoWeProvideSection.jsx";
import OurPromisesSection from "./OnboardingPageComponents/OurPromisesSection.jsx";
import ProblemSolvingSection from "./OnboardingPageComponents/ProblemSolvingSection.jsx";
import FloatingButton from "../Components/FloatingButton.jsx";

// 중복되는 스타일을 변수로 추출
// Device 변화에 대비해 좌우 24px 외 나머지 width 길이가 되도록 적용
const commonStyles = css`
  width: calc(100% - 48px);
  margin: 0 24px;
`;

const Onboarding = () => {
  // 쿠키 확인 및 리디렉션
  const navigate = useNavigate();

  // 네이버 앱에서 접속했는지를 확인하여 구글 로그인 지원 불가 메시지를 사용자에게 알리기 위한 함수
  // 모바일 디바이스에서 네이버 앱을 통해 접속 시, 구글 로그인 오류(403)를 사전에 안내한다
  const isNaverApp = () => {
    // navigator.userAgent를 통해 브라우저나 앱의 정보를 가져온다
    const userAgent = navigator.userAgent || window.opera;
    // 'NAVER'라는 문자열이 포함되어 있는지 검사하여 네이버 앱 접속 여부를 확인
    return /NAVER/i.test(userAgent);
  };

  useEffect(() => {
    // 네이버 앱에서 접속 시 다른 브라우저로 재접속할 수 있도록 안내하기
    if (isNaverApp()) {
      alert(
        "현재 네이버 앱에서는 '구글 로그인'이 지원되지 않습니다. 원활한 이용을 위해 Google 앱, Chrome 앱, Safari(또는 '인터넷' 앱), 또는 PC 브라우저를 사용해 주세요.\n\nCurrently, Google login is not supported in the Naver app. For a better experience, please use the Google app, Chrome app, Safari (or the 'Internet' app), or a PC browser."
      );
    }
  }, []);

  // 버튼 클릭시 온보딩 페이지의 맨 위로 스르륵 부드럽게 올라옴
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const loginInfo = Cookies.get("lawlow");
    if (loginInfo) {
      navigate("/main");
    }
  }, [navigate]);

  /* Video PC, Mobile 변경 */
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 430);
  /* Logo PC, Mobile 변경 */
  const [isTablet, setIsTablet] = React.useState(window.innerWidth <= 960);

  /* PWA 설치 묻는 모달창 */
  const [isAndroidPWAModalOpen, setAndroidPWAModalIsOpen] = useState(false);
  const [isiOSPWAModalOpen, setiOSPWAModalIsOpen] = useState(false);

  /* 이용약관 모달창 */
  const [isTermsModalOpen, setTermsModalIsOpen] = useState(false);
  const handleOpenTermsModal = () => {
    //setTermsModalIsOpen(true);
    window.location.href = `${window.location.origin}/termsofservice`;
  };
  const handleCloseTermsModal = () => {
    setTermsModalIsOpen(false);
  };

  /* 개인정보 처리방침 모달창 */
  const [isPrivacyModalOpen, setPrivacyModalIsOpen] = useState(false);
  const handleOpenPrivacyModal = () => {
    // setPrivacyModalIsOpen(true);
    window.location.href = `${window.location.origin}/privacypolicy`;
  };
  const handleClosePrivacyModal = () => {
    setPrivacyModalIsOpen(false);
  };

  /* SNS 로그인 */
  const KakaoLogin = () => {
    let redirectUri = process.env.REACT_APP_KAKAO_REDIRECT_URI_DEPLOY;

    // 현재 요청한 주소가 로컬 환경인 경우 리다이렉트 주소 설정 변경
    if (window.location.origin === "http://localhost:3000") {
      redirectUri = process.env.REACT_APP_KAKAO_REDIRECT_URI_LOCAL;
    }

    const KAKAO_LOGIN_URL =
      `https://kauth.kakao.com/oauth/authorize?` +
      `response_type=code&` +
      `client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&` +
      `redirect_uri=${redirectUri}`;

    window.location.href = KAKAO_LOGIN_URL; // (프론트 → 카카오)로그인 요청 URL
  };

  const GoogleLogin = () => {
    let redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI_DEPLOY;

    // 현재 요청한 주소가 로컬 환경인 경우 리다이렉트 주소 설정 변경
    if (window.location.origin === "http://localhost:3000") {
      redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI_LOCAL;
    }

    // const GOOGLE_LOGIN_URL =
    //   `https://accounts.google.com/o/oauth2/v2/auth?` +
    //   `scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile&` +
    //   `access_type=offline&` +
    //   `include_granted_scopes=true&` +
    //   `response_type=code&` +
    //   `state=state_parameter_passthrough_value&` +
    //   `redirect_uri=${redirectUri}&` +
    //   `client_id=${process.env.REACT_APP_GOOGLE_REST_API_KEY}`;

    const GOOGLE_LOGIN_URL = `https://accounts.google.com/o/oauth2/v2/auth?scope=email%20openid&response_type=code&redirect_uri=${redirectUri}&client_id=${process.env.REACT_APP_GOOGLE_REST_API_KEY}`;

    window.location.href = GOOGLE_LOGIN_URL;
  };

  const NaverLogin = () => {
    const NAVER_LOGIN_URL =
      `https://nid.naver.com/oauth2.0/authorize?client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&` +
      `response_type=code&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}&` +
      `state=${process.env.REACT_APP_NAVER_STATE}`;

    window.location.href = NAVER_LOGIN_URL;
  };

  /* isMobile */

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 579); // Mobile / 579 - 320
    setIsTablet(window.innerWidth <= 959); // Tablet / 1399 - 580
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /* 홈 화면 추가 버튼을 누른 경우 팝업 창 뜨게 하기 */
  const handlePWAAppDown = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/i.test(userAgent);

    if (isIOS) {
      setiOSPWAModalIsOpen(true);
    } else {
      setAndroidPWAModalIsOpen(true);
    }
  };

  /* 공유하기 버튼을 누른 경우 활성화되는 하단창 */
  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "로우로우 링크 공유하기",
          url: window.location.href, // 현재 페이지의 URL
        });
        console.log("공유가 성공적으로 완료되었습니다.");
      } else {
        fallbackShare();
      }
    } catch (error) {
      console.error("공유 중 오류가 발생했습니다.", error);
      fallbackShare();
    }
  };

  const fallbackShare = () => {
    const shareText = `${window.location.href}`; // 보드에 뜨는 메세지
    navigator.clipboard.writeText(shareText).then(
      () => {
        alert("링크가 클립보드에 복사되었습니다: " + shareText);
      },
      (error) => {
        console.error("클립보드에 복사하는 중 오류가 발생했습니다.", error);
      }
    );
  };

  return (
    // <Stop />
    <>
      <Helmet>
        <title>
          로우로우 Lawlow - 변호사 상담은 부담스러울 때, 누구나 이용하기 쉬운
          판례검색 서비스
        </title>
        <meta
          name="description"
          content="나에게 꼭 필요한 법률정보를 얻어가세요! 법원의 판결, 공통점, 차이점까지 내 상황과 비슷한 판례를 한눈에!"
        />
        <meta
          name="keywords"
          content="변호사, 변호사 추천, 선임비용, 무료법률상담, 전화법률상담, 법률상담, 법, 법률, 사기, 이혼, 범죄, 채무"
        />
      </Helmet>
      <Desktop>
        <FloatingButton></FloatingButton>
        <MainWrapper>
          <Banner>
            <Logo />
            <WidthInterval width={854} />
            <BadgeAndButtonWrapper>
              <FreeTrialImage src={freeTrialBadge} alt="무료체험가능" />
              <FreeTrialButton onClick={scrollToTop}>
                간편로그인
              </FreeTrialButton>
            </BadgeAndButtonWrapper>
          </Banner>
          <HeightInterval height={229} />
          <div className={styles.main}>
            <div className={styles.main_left}>
              <div className={styles.intro}>
                <TextBanner>
                  990원짜리 나만의 법조인,
                  <br /> <TextGradation>내 상황에 맞는 판례</TextGradation>를
                  찾다
                </TextBanner>
                <div className={styles.intro_bar}>
                  <HrStyles width={416} />
                </div>
              </div>
              <HeightInterval height={32} />
              <div className={styles.auto_text}>
                <GifBox>
                  <Video autoPlay loop muted playsInline src={Video_PC} />
                </GifBox>
              </div>
            </div>
            <WidthInterval width={110} />
            <div className={styles.main_right}>
              <WrapperSns>
                <div className={styles.sns}>
                  <img src={desktop_login_bg} alt="sns-background" />
                </div>
                <div className={styles.sns_contents}>
                  <div className={styles.sns_text}>
                    <Text size="24px" weight="700" height="normal">
                      간편 로그인
                    </Text>
                    <HeightInterval height={28} />
                    <Text size="18px" color="#000" weight="500" height="28px">
                      가입 시 15로우 무료 제공!
                    </Text>
                    <HeightInterval height={8} />
                    <Text
                      size="16px"
                      color="rgba(0, 0, 0, 0.30)"
                      weight="400"
                      height="20px">
                      검색 & 내 상황과 판례 비교 1회 무료 이용
                    </Text>
                    <HeightInterval height={60} />
                    <LoginSelect>
                      <HrStyles width={142} />
                      <Text
                        size="14px"
                        color="rgba(0, 0, 0, 0.30)"
                        weight="500"
                        height="20px"
                        margin="0 12px">
                        로그인 방법 선택
                      </Text>
                      <HrStyles width={142} />
                    </LoginSelect>
                  </div>
                  <div className={styles.sns_img}>
                    <img
                      src={google}
                      alt="google"
                      onClick={GoogleLogin}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                    <HeightInterval height={12} />
                    <img
                      src={naver}
                      alt="naver"
                      onClick={NaverLogin}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                    <HeightInterval height={12} />
                    <img
                      src={kakao}
                      alt="kakao"
                      onClick={KakaoLogin}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </WrapperSns>
            </div>
          </div>
        </MainWrapper>
        <StatisticsSection></StatisticsSection>
        <ReviewSection></ReviewSection>
        <WhoAreWeSection></WhoAreWeSection>
        <WhyDoesItMatterSection></WhyDoesItMatterSection>
        <WhatDoWeProvideSection></WhatDoWeProvideSection>
        <OurPromisesSection></OurPromisesSection>
        <ProblemSolvingSection></ProblemSolvingSection>
        {/* <MainImgDesktop>
          <img src={pc_expanded_onboarding_img} alt="Expanded Onboarding" />
          <ButtonPC1 onClick={scrollToTop} />
          <ButtonPC2 onClick={scrollToTop} />
        </MainImgDesktop> */}
        <FooterDesktop>
          <FooterInfo>
            <FooterLeftSide>
              <Text
                size="20px"
                color="rgba(0, 0, 0, 0.30)"
                weight="700"
                height="normal">
                Lawlow
              </Text>
              <HeightInterval height={12} />
              <Text
                size="11px"
                color="rgba(0, 0, 0, 0.30)"
                weight="500"
                height="normal">
                고객센터: lawlow.korea@gmail.com ㅣ 대표: 조준영 ㅣ 대표 연락처:
                070-8080-0309 | 상호: 로우로우(Lawlow)
                <HeightInterval height={8} />
                사업자 등록번호: 302-57-00671 ㅣ사업장 소재지: 서울특별시
                영등포구 국회대로36길 6-1, 2층 -J81호(당산동3가)
                <HeightInterval height={8} />
                민원담당자: 조준영ㅣ민원실: 070-8080-0309ㅣ 개인정보관리책임자:
                김훈기
                <HeightInterval height={8} />
                통신판매업: 2024-서울영등포-1585
              </Text>
            </FooterLeftSide>
            <FooterRightSide>
              <ModalsTermsInquiry>
                <ModalTermsOfService
                  isOpen={isTermsModalOpen}
                  onClose={handleCloseTermsModal}
                />
                <ModalPrivacyPolicy
                  isOpen={isPrivacyModalOpen}
                  onClose={handleClosePrivacyModal}
                />
                {/*  클릭 시 setModalIsOpen 상태 변경 함수 호출(모달 컴포넌트는 onClick() 호출 앞에 있어야함) */}
                <ModalText onClick={() => handleOpenTermsModal(true)}>
                  이용약관↗
                </ModalText>
                <ModalText onClick={() => handleOpenPrivacyModal(true)}>
                  개인정보 처리방침↗
                </ModalText>
              </ModalsTermsInquiry>
              <HeightInterval height={16} />
              <Text
                size="11px"
                color="rgba(0, 0, 0, 0.30)"
                weight="500"
                height="normal">
                LAWLOW에서 운영되는 사이트 내 판매되는 모든 상품은 LAWLOW에서
                책임지고 있습니다.
                <HeightInterval height={8} />
                LAWLOW의 사전 서면 동의 없이 사이트의 일체의 정보, 콘텐츠 및 UI
                등을 상업적 목적으로 전재, 전송, 스크래핑 등 무단 사용할 수
                없습니다.
                <HeightInterval height={29} />
                Copyright ⓒ 2024 Lawlow Inc. All right reserved.
              </Text>
            </FooterRightSide>
          </FooterInfo>
        </FooterDesktop>
        {/* </Wrapper> */}
      </Desktop>

      <Mobile>
        {/* PWA */}
        <ModalAddToHomeScreenPrompt
          isOpen={isAndroidPWAModalOpen}
          onClose={() => setAndroidPWAModalIsOpen(false)}
        />
        <ModalIOSAddToHomeScreen
          isOpen={isiOSPWAModalOpen}
          onClose={() => setiOSPWAModalIsOpen(false)}
        />
        <WrapperWithGradientBackground>
          <WrapperContents>
            {/* <WrapperBanner> */}
            <BannerMobile>
              <HeightInterval height={24} />
              <IconsContainer>
                <Icon
                  src={homeIcon}
                  onClick={handlePWAAppDown}
                  alt="home icon"
                />

                <Logo />
                <Icon src={shareIcon} onClick={handleShare} alt="share icon" />
              </IconsContainer>
              <HeightInterval height={36} />
              <TextBanner>
                990원짜리 나만의 법조인,
                <br />
                <TextGradation>내 상황에 맞는 판례</TextGradation>를 찾다
              </TextBanner>
              <Underline src={underline} alt="underline" />
            </BannerMobile>
            {/* </WrapperBanner> */}
            <Main>
              <HeightInterval height={28} />
              <GifBox>
                <Video
                  autoPlay
                  loop
                  muted
                  playsInline
                  src={isMobile ? Video_Mobile : Video_PC}
                />
              </GifBox>
              <HeightInterval height={24} />
              <Sns>
                <SnsText>
                  <HeightInterval height={22} />
                  <Text size="16px" color="#000" weight="600">
                    가입 시 15로우 무료 제공!
                  </Text>
                  <HeightInterval height={6} />
                  <Text size="14px" color="rgba(0, 0, 0, 0.30)" weight="400">
                    검색 & 내 상황과 판례 비교 1회 이상
                  </Text>
                  <HeightInterval height={20} />
                </SnsText>
                <HrStyles width="100%" />
                <HeightInterval height={24} />
                <SnsImg>
                  <img
                    src={m_google}
                    width="56px"
                    height="56px"
                    alt="google"
                    onClick={GoogleLogin}
                  />
                  <img
                    src={m_naver}
                    width="56px"
                    height="56px"
                    alt="naver"
                    onClick={NaverLogin}
                  />
                  <img
                    src={m_kakao}
                    width="56px"
                    height="56px"
                    alt="kakao"
                    onClick={KakaoLogin}
                  />
                </SnsImg>
              </Sns>
              <HeightInterval height={104} />
            </Main>
          </WrapperContents>
        </WrapperWithGradientBackground>
        <LargeTablet>
          {/* <MainImgLargeTablet>
            <img src={pc_expanded_onboarding_img} alt="Expanded Onboarding" />
            <ButtonLT1 onClick={scrollToTop} />
            <ButtonLT2 onClick={scrollToTop} />
          </MainImgLargeTablet> */}
        </LargeTablet>
        <SmallTablet>
          {/* <MainImgSmallTablet>
            <img src={pc_expanded_onboarding_img} alt="Expanded Onboarding" />
            <ButtonST1 onClick={scrollToTop} />
            <ButtonST2 onClick={scrollToTop} />
          </MainImgSmallTablet> */}
        </SmallTablet>
        {/* <MainImgMobile>
          <img src={mobile_expanded_onboarding_img} alt="Expanded Onboarding" />
          <ButtonMobile1 onClick={scrollToTop} />
          <ButtonMobile2 onClick={scrollToTop} />
        </MainImgMobile> */}
        {/* 모바일 */}
        <StatisticsSection></StatisticsSection>
        <ReviewSection></ReviewSection>
        <WhoAreWeSection></WhoAreWeSection>
        <WhyDoesItMatterSection></WhyDoesItMatterSection>
        <WhatDoWeProvideSection></WhatDoWeProvideSection>
        <OurPromisesSection></OurPromisesSection>
        <ProblemSolvingSection></ProblemSolvingSection>
        <FooterMobile>
          <TextContainer>
            <TextWrapper>
              <Text
                size="18px"
                color="rgba(0, 0, 0, 0.30)"
                weight="700"
                line-height="normal">
                Lawlow
              </Text>
              <WidthInterval width={12} />
              <ModalTermsOfService
                isOpen={isTermsModalOpen}
                onClose={handleCloseTermsModal}
              />
              <ModalPrivacyPolicy
                isOpen={isPrivacyModalOpen}
                onClose={handleClosePrivacyModal}
              />
              {/*  클릭 시 setModalIsOpen 상태 변경 함수 호출(모달 컴포넌트는 onClick() 호출 앞에 있어야함) */}
              <ModalText onClick={() => handleOpenTermsModal(true)}>
                이용약관↗
              </ModalText>
              <ModalText onClick={() => handleOpenPrivacyModal(true)}>
                개인정보 처리방침↗
              </ModalText>
            </TextWrapper>
            {!isTablet && (
              <IsTabletTextContainer>
                <br />
                <Text size="10px" color="rgba(0, 0, 0, 0.30)" weight="500">
                  Copyright ⓒ 2024 Lawlow Inc. All right reserved.
                </Text>
              </IsTabletTextContainer>
            )}
          </TextContainer>
          <HeightInterval height={14} />
          <TextContainer2>
            <Text
              size="10px"
              color="rgba(0, 0, 0, 0.30)"
              weight="500"
              height="18px">
              고객센터: lawlow.korea@gmail.com ㅣ대표 연락처: 070-8080-0309{" "}
              <br />
              사업자 등록번호: 302-57-00671 ㅣ상호: 로우로우(Lawlow) ㅣ대표:
              조준영
              <br />
              사업장 소재지: 서울특별시 영등포구 국회대로36길 6-1, 2층
              -J81호(당산동3가)
              <br />
              개인정보관리책임자: 김훈기ㅣ민원담당자: 조준영 ㅣ 민원실:
              070-8080-0309 <br /> 통신판매업: 2024-서울영등포-1585
            </Text>
            <HeightInterval height={16} />
            <Text
              size="9px"
              color="rgba(0, 0, 0, 0.30)"
              weight="500"
              height="16px">
              LAWLOW에서 운영되는 사이트 내 판매되는 모든 상품은 LAWLOW에서
              책임지고 있습니다. <br /> LAWLOW의 사전 서면 동의 없이 사이트의
              일체의 정보, 콘텐츠 및 UI 등을 상업적 목적으로
              <br /> 전재, 전송, 스크래핑 등 무단 사용할 수 없습니다.
            </Text>
            <HeightInterval height={7} />
            {isTablet && (
              <Text size="10px" color="rgba(0, 0, 0, 0.30)" weight="500">
                Copyright ⓒ 2024 LAWLOW Inc. All right reserved.
              </Text>
            )}
          </TextContainer2>
        </FooterMobile>
      </Mobile>
    </>
  );
};

const Banner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 21px;
  padding-bottom: 33px;
  position: fixed; /* Keeps the banner at the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 100; /* Lowered the z-index */
  background-color: #f9f9f9;

  // 860px(임시 height) 이하의 해상도일 경우 footer 겹침으로 인한 간격 조정
  @media (max-height: 860px) {
    margin-bottom: 41px;
  }
`;

const BadgeAndButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FreeTrialImage = styled.img`
  width: 100px;
  height: 31px;
`;

const FreeTrialButton = styled.button`
  border: none;
  width: 144px;
  height: 48px;
  border-radius: 32px;
  background: var(
    --btn-gradient,
    radial-gradient(
      67.34% 67.63% at 57% 63.81%,
      rgba(0, 243, 112, 0.6) 55.56%,
      rgba(0, 194, 255, 0.6) 100%
    )
  );
  cursor: pointer;
  color: #000;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const TextBanner = styled.div`
  color: #000;
  font-family: Pretendard;
  margin: 0px;

  @media (max-width: 10000px) and (min-width: 1440px) {
    font-family: Pretendard;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    margin-right: 15px;
  }

  @media (max-width: 1439px) and (min-width: 960px) {
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    margin-right: 15px;
    text-align: center;
    ${commonStyles}
  }

  @media (max-width: 959px) and (min-width: 580px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    ${commonStyles}
  }

  @media (max-width: 579px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    ${commonStyles}
  }
`;

// Mobile
const WrapperWithGradientBackground = styled.div`
  background-image: url(${mobile_login_bg});
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const WrapperContents = styled.div`
  width: 100%;
`;

const BannerMobile = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GifBox = styled.div`
  border-radius: 24px;
  overflow: hidden; /* 비디오가 컨테이너를 넘어가는 부분을 숨기기 위해 추가 */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid rgba(0, 0, 0, 0.1); /* 비디오 테두리 */

  @media (max-width: 10000px) and (min-width: 1440px) {
    width: 726px;
    height: auto;
    outline: none;
  }

  @media (max-width: 1439px) and (min-width: 960px) {
    width: 726px;
    height: auto;
    outline: none;
  }

  @media (max-width: 959px) and (min-width: 580px) {
    width: 520px;
    height: auto;
    outline: none;
  }

  @media (max-width: 579px) and (min-width: 430px) {
    ${commonStyles}/* 좌우 width 24px */
  }

  @media (max-width: 429px) {
    ${commonStyles}/* 좌우 width 24px */
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain; /* 비디오의 크기를 조정하여 컨테이너를 채우도록 설정 */
  border-radius: inherit; /* 컨테이너의 border-radius를 상속 */
`;

const FooterMobile = styled.div`
  // width: 869px;
  height: 269px;
  bottom: 0; /* 화면 하단 고정 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 16px;
  // padding-left: 20px;

  @media (max-width: 1439px) and (min-width: 960px) {
    // width: calc(100% - 314px);
    flex-direction: row;
    // align-items: center;
    justify-content: center;
  }

  @media (max-width: 959px) and (min-width: 580px) {
    height: 269px;
    margin: 0 auto;
    padding: 0 calc((100vw - 320px) / 3); /* 좌우 패딩을 비례적으로 조정 */
  }

  @media (max-width: 579px) and (min-width: 340px) {
    flex-direction: column;
    padding-left: 24px;
  }

  @media (max-width: 339px) {
    flex-direction: column;
    padding-top: 24px;
    padding-left: 24px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1439px) {
    flex-direction: column;
    padding-top: 35px;
    padding-right: 35px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextContainer2 = styled.div`
  @media (max-width: 1439px) {
    padding-top: 30px;
  }
`;

const IsTabletTextContainer = styled.div``;

const IconsContainer = styled.div`
  ${commonStyles};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.img`
  // 아이콘 크기는 임의로 설정해둠(디자인 변경 필요)
  @media (max-width: 1439px) and (min-width: 960px) {
    width: 72px;
    height: 72px;
  }

  @media (max-width: 959px) and (min-width: 580px) {
    width: 54px;
    height: 54px;
  }

  @media (max-width: 579px) {
    width: 36px;
    height: 36px;
  }
`;

const TextGradation = styled.span`
  background: linear-gradient(90deg, #01f370 13.62%, #3d7fff 58.98%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Underline = styled.img`
  width: 178px;
  height: 6.92px;
  align-self: center;

  @media (max-width: 1439px) and (min-width: 960px) {
    margin-right: 30px;
  }

  @media (max-width: 959px) and (min-width: 580px) {
    margin-right: 60px;
  }

  @media (max-width: 579px) {
    margin-right: 70px;
  }
`;

// Desktop
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 868px; // 1024px에서 푸터 height(156px)를 제외
  background: #f9f9f9;
`;

// 온보딩 확장반_PC_Tablet
const MainImgDesktop = styled.div`
  @media (max-width: 10000px) and (min-width: 1440px) {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  img {
    width: 100%;
    display: block;
  }
`;

const MainImgLargeTablet = styled.div`
  @media (max-width: 1439px) and (min-width: 960px) {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 959px) and (min-width: 580px) {
    display: none;
  }

  img {
    width: 100%;
    display: block;
  }
`;

const MainImgSmallTablet = styled.div`
  @media (max-width: 1439px) and (min-width: 960px) {
    display: none;
  }

  @media (max-width: 959px) and (min-width: 580px) {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  img {
    width: 100%;
    display: block;
  }
`;

const MainImgMobile = styled.div`
  @media (max-width: 10000px) and (min-width: 580px) {
    display: none;
  }
  @media (max-width: 579px) and (min-width: 320px) {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  img {
    width: 100%;
    display: block;
  }
`;

const ButtonBase = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; /* 추가: z-index 설정 */
  background: url(${legal_info_button}) no-repeat center center;
  background-size: contain;
  cursor: pointer;
  width: 399px;
  height: 64px;
`;

const ButtonPC1 = styled(ButtonBase)`
  @media (max-width: 10000px) and (min-width: 1440px) {
    top: 13%; /* top 값을 뷰포트 높이를 기준으로 동적으로 설정 */
  }
`;

const ButtonPC2 = styled(ButtonBase)`
  @media (max-width: 10000px) and (min-width: 1440px) {
    bottom: 2%; /* FooterDesktop 위로 138px 위치 */
  }
`;

const ButtonLT1 = styled(ButtonBase)`
  @media (max-width: 1439px) and (min-width: 960px) {
    top: 13%;
  }
`;

const ButtonLT2 = styled(ButtonBase)`
  @media (max-width: 1439px) and (min-width: 960px) {
    bottom: 1.7%; /* FooterDesktop 위로 138px 위치 */
  }
`;

const ButtonST1 = styled(ButtonBase)`
  @media (max-width: 959px) and (min-width: 580px) {
    top: 13%;
  }
`;

const ButtonST2 = styled(ButtonBase)`
  @media (max-width: 959px) and (min-width: 580px) {
    bottom: 1%; /* FooterDesktop 위로 138px 위치 */
  }
`;

const ButtonMobile1 = styled(ButtonBase)`
  @media (max-width: 579px) {
    width: 309px;
    height: 51px;
    top: 19.7%;
  }
`;

const ButtonMobile2 = styled(ButtonBase)`
  @media (max-width: 579px) {
    width: 309px;
    height: 51px;
    bottom: 1.3%;
  }
`;

const WrapperSns = styled.div`
  display: flex;
  justify-content: center;
`;

const ModalText = styled.div`
  display: inline-block;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;

  &:hover {
    cursor: pointer;
  }
`;

const Sns = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: space-evenly;
  align-items: center;

  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 20px 5px rgba(0, 0, 0, 0.05);
  z-index: 1;

  @media (max-width: 1439px) and (min-width: 960px) {
    width: 726px;
    height: 189px;
  }

  @media (max-width: 959px) and (min-width: 580px) {
    width: 520px;
    height: 189px;
  }

  @media (max-width: 579px) {
    ${commonStyles} /* 좌우 width 24px */
    height: 189px;
  }
`;

const SnsText = styled.div`
  /* 자식 요소 수직 수평 가운데 정렬 */
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
`;

const SnsImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px; /* 두 이미지 사이 간격을 24px로 설정 */
  padding: 0 24px; /* 양쪽 끝의 간격을 동일하게 유지 */
`;

const HrStyles = styled.hr`
  width: ${(props) => props.width}px;
  height: 0px;
  flex-shrink: 0;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  margin: 0px;
  padding: 0px;
`;

const LoginSelect = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 404px;
`;

const FooterDesktop = styled.div`
  background: #fff;
  width: 100%;
  height: 156px;
`;

const FooterInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 21px;
`;

const FooterLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 647px;
`;

const FooterRightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
`;

const ModalsTermsInquiry = styled.div``;

export default Onboarding;
