import React from "react";
import styled from "styled-components";
import { Desktop, Mobile } from "../../MediaQuery/OnboardingPageMediaQuery.js";
import img1 from "../../img/onb-page/provide_img_1.png";
import img2 from "../../img/onb-page/provide_img_2.png";
import img3 from "../../img/onb-page/provide_img_3.png";
import img4 from "../../img/onb-page/provide_img_4.png";

const WhatDoWeProvideSection = () => {
  return (
    <Container>
      {/* 제목 */}
      <Header>What do we provide?</Header>

      {/* 부제목 */}
      <SubTitle>
        간단한 입력 한 번으로 가장 쉽게, <br />
        가장 효과적인 문제의 해결책을 찾아보세요!
      </SubTitle>
      {/* 첫 번째: 이미지 + 텍스트 */}
      <ImageTextWrapper>
        <ImageContainer>
          <img src={img1} alt="Provide img 1" />
        </ImageContainer>
        <TextContainer>
          <Title>친구에게 털어놓듯</Title>
          <SubText>
            내가 겪고 있는 문제상황을 친구에게 털어놓듯 <br />
            자유롭게 이야기해보세요.
          </SubText>
        </TextContainer>
      </ImageTextWrapper>

      {/* 두 번째: 텍스트 + 이미지 */}
      <Desktop>
        <TextImageWrapper>
          <TextContainer>
            <Title>고급 법률 정보 ‘판례’를</Title>
            <SubText>
              내 문제상황이 실제로 법원에서 어떻게 해결되었는지 <br />
              가장 자세하고, 다양하게 알려드려요.
            </SubText>
          </TextContainer>
          <ImageContainer>
            <img src={img2} alt="Provide img 2" />
          </ImageContainer>
        </TextImageWrapper>
      </Desktop>

      <Mobile>
        <ImageTextWrapper>
          <ImageContainer>
            <img src={img2} alt="Provide img 2" />
          </ImageContainer>
          <TextContainer>
            <Title>고급 법률 정보 ‘판례’를</Title>
            <SubText>
              내 문제상황이 실제로 법원에서 어떻게 해결되었는지 <br />
              가장 자세하고, 다양하게 알려드려요.
            </SubText>
          </TextContainer>
        </ImageTextWrapper>
      </Mobile>

      {/* 세 번째: 이미지 + 텍스트 */}
      <ImageTextWrapper>
        <ImageContainer>
          <img src={img3} alt="Provide img 3" />
        </ImageContainer>
        <TextContainer>
          <Title>누구나 이해하기 쉬운 말로</Title>
          <SubText>
            <Desktop>
              판례에 등장하는 법률용어, 복잡한 정보들. 누구나 한눈에 이해할{" "}
              <br />수 있도록 Lawlow가 해석하여 요약해드려요.{" "}
            </Desktop>
            <Mobile>
              {" "}
              판례에 등장하는 법률용어, 복잡한 정보들. 누구나 한눈에 <br />{" "}
              이해할 수 있도록 Lawlow가 해석하여 요약해드려요.{" "}
            </Mobile>
          </SubText>
        </TextContainer>
      </ImageTextWrapper>

      {/* 네 번째: 텍스트 + 이미지 */}
      <Desktop>
        <TextImageWrapper>
          <TextContainer>
            <Title>내 상황과 친절한 비교까지!</Title>
            <SubText>
              <Desktop>
                각 판례와 내 상황의 공통점과 차이점을 AI가 분석해드려요. <br />
                내 상황의 특수성에 맞는 유의미한 정보만을 빠르게 색출할 수
                있습니다.
              </Desktop>
              <Mobile>
                각 판례와 내 상황의 공통점과 차이점을 AI가
                <br /> 분석해드려요. 내 상황의 특수성에 맞는 유의미한
                <br /> 정보만을 빠르게 색출할 수 있습니다.
              </Mobile>
            </SubText>
          </TextContainer>
          <ImageContainer>
            <img src={img4} alt="Provide img 4" />
          </ImageContainer>
        </TextImageWrapper>
      </Desktop>
      <Mobile>
        <ImageTextWrapper>
          <ImageContainer>
            <img src={img4} alt="Provide img 4" />
          </ImageContainer>
          <TextContainer>
            <Title>내 상황과 친절한 비교까지!</Title>
            <SubText>
              <Desktop>
                각 판례와 내 상황의 공통점과 차이점을 AI가 분석해드려요. <br />
                내 상황의 특수성에 맞는 유의미한 정보만을 빠르게 색출할 수
                있습니다.
              </Desktop>
              <Mobile>
                각 판례와 내 상황의 공통점과 차이점을 AI가
                <br /> 분석해드려요. 내 상황의 특수성에 맞는 유의미한
                <br /> 정보만을 빠르게 색출할 수 있습니다.
              </Mobile>
            </SubText>
          </TextContainer>
        </ImageTextWrapper>
      </Mobile>
    </Container>
  );
};

// 스타일링
const Container = styled.div`
  height: 1577px;
  background: #f9f9f9;
  padding: 100px 100px 126px;

  // 임시
  @media (max-width: 1439px) {
    height: 1377px;
    padding: 100px 20px 126px;
  }

  @media (max-width: 959px) {
    height: 1778px;
    padding: 64px 28px;
  }
`;

const Header = styled.h2`
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin: 0;
`;

const SubTitle = styled.h1`
  color: #000;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  margin: 0;
  margin-top: 24px;

  @media (max-width: 959px) {
    font-size: 24px;
    line-height: 36px;
    margin-top: 25px;
  }
`;

{
  /* 첫,세 번째: 이미지 + 텍스트 순서 */
}
const ImageTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 150px;
  align-items: center;
  margin: 0;

  // 임시
  @media (max-width: 1439px) {
    gap: 20px;
    justify-content: space-around;
  }

  @media (max-width: 959px) {
    gap: 0px;
    flex-direction: column;
    margin: 0;
    margin-top: 48px;
  }
`;

{
  /* 두,네 번째: 텍스트 + 이미지 순서 */
}
const TextImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 150px;
  align-items: center;
  margin-bottom: 80px;
  margin: 0;

  // 임시
  @media (max-width: 1439px) {
    gap: 20px;
    justify-content: space-around;
  }

  @media (max-width: 959px) {
    gap: 0px;
    flex-direction: column;
    margin: 0 38px;
  }
`;

const TextContainer = styled.div`
  width: 40%;
  margin-left: 2%;

  // 임시
  @media (max-width: 1439px) {
    margin-left: 20px;
  }

  @media (max-width: 959px) {
    width: auto;
    display: grid;
    place-items: center;
    margin-top: 15px;
    margin-left: 0px;
  }
`;

const Title = styled.h2`
  color: #000;
  font-family: Pretendard;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin: 0;
  margin-bottom: 32px;
  text-align: left;

  // 임시
  @media (max-width: 1439px) {
    font-size: 40px;
  }

  @media (max-width: 959px) {
    font-size: 20px;
    margin-bottom: 4px;
  }
`;

const SubText = styled.p`
  color: #303030;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: left;

  @media (max-width: 959px) {
    font-size: 14px;
    line-height: 23px;
    margin: 0;

    display: block;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  width: 43%;
  height: 347px;
  flex-shrink: 0;
  border-radius: 24px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    border-radius: 24px;
  }

  @media (max-width: 1439px) {
    width: 489px;
    height: 307px;
  }

  @media (max-width: 959px) {
    width: 312px;
    height: 244px;
  }
`;

export default WhatDoWeProvideSection;
