import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import copy from "../img/copy.png";
import search from "../img/search.png";
import toggle_down from "../img/toggle_down.png";
import toggle_up from "../img/toggle_up.svg";
import CaseSummary from "./CaseSummary.jsx";
import Text from "../Components/Text.jsx";
import Button from "../Components/Button.jsx";
import HeightInterval from "../Components/HeightInterval.jsx";
import CaseComparison from "../Components/CaseComparison.jsx";
import ModalPayCheck from "./Modal/ModalPayCheck";
import RelevanceChart from "../Components/ApexChart/RelevanceChart.jsx";
import TooltipComponent from "./Tooltip.jsx";
import { gradientBorder } from "../styles/mixin.js";
import useStore from "../store.js";

/* ResultForm Mobile 버전 */
const ResultFormMobile = ({
  index,
  courtName,
  sentencingData,
  caseNumber,
  caption,
  relevance,
  question,
  detail,
  answer,
  caseId,
  isComparison,
  commonality,
  difference,
}) => {
  const [showCompare, setShowCompare] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // CaseComparison의 props로부터 추가된 로딩 상태
  const { setShowCopyCaseSnackbar } = useStore();

  // 스낵바는 Mobile에만 우선 적용하기 위해 위해 현재 화면의 가로 크기 가져오기
  const screenWidth = window.innerWidth;

  /* CaseTime 컴포넌트 height에 따라 Header의 margin-bottom px값 변경 */
  const [caseTitleHeight, setCaseTitleHeight] = useState(0);
  const caseTitleRef = useRef(null);
  useEffect(() => {
    if (caseTitleRef.current) {
      setCaseTitleHeight(caseTitleRef.current.clientHeight);
    }
  }, []);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleConfirmModal = () => {
    setShowModal(false); // 모달 닫기
    setShowCompare(true); // CaseComparison 보여주기
  };

  // 구글에 원문 검색
  const handleSearch = () => {
    const query = `${courtName} ${caseNumber}`;
    const url = `https://www.google.com/search?q=${encodeURIComponent(query)}`;
    window.open(url, "_blank");
  };

  // 복사하기
  const handleCopy = () => {
    const textToCopy = `
[판례 번호]
${courtName} ${sentencingData} ${caseNumber}

[문제]
${question}

[결론]
${answer}

[내용]
${detail}
    `;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        // 화면 해상도가 1140px 이하일 때(Mobile, Tablet) 스낵바 사용
        if (screenWidth <= 1140) {
          setShowCopyCaseSnackbar(true);
          // 3초 후 애니메이션이 시작되고, 애니메이션이 완료된 후 사라지게 설정
          setTimeout(() => {
            setShowCopyCaseSnackbar(false);
          }, 6000); // 3초 유지 + 3초 동안 애니메이션 실행 = 총 6초 후 상태 변경
        } else {
          // 해상도가 1140px 보다 클 때(Desktop) window.alert 사용
          alert("판례 내용이 복사되었습니다");
        }
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  /* 이후 - 판례 요약 접어두기 기능 구현  */
  // const [isContentVisible, setIsContentVisible] = useState(false);
  // const handleToggleContent = () => {
  //   setIsContentVisible(!isContentVisible);
  // };

  return (
    <WrapperMobile>
      <Header caseTitleHeight={caseTitleHeight}>
        <TitleAndNumber>
          <Number>#{index + 1}</Number>
          <CaseTitle ref={caseTitleRef}>
            {courtName} {sentencingData} {caseNumber} / {caption}
          </CaseTitle>
        </TitleAndNumber>
        <Relevance>
          <RelevanceChart relevance={relevance} />
          <Text
            size="10px"
            color="rgba(0, 0, 0, 0.30)"
            weight="400"
            lineHeight="14px">
            관련도
          </Text>
        </Relevance>
      </Header>
      <HrStyles />
      {/* {showCompare && (
        <Overlay>
          <ToggleButton onClick={handleToggleContent}>
            <ToggleText>판례 요약 다시 펼쳐보기</ToggleText>
            {isContentVisible ? (
              <img src={toggle_up} width="8px" height="4px" alt="토글 올리기" />
            ) : (
              <img
                src={toggle_down}
                width="8px"
                height="4px"
                alt="토글 내리기"
              />
            )}
          </ToggleButton>
        </Overlay>
      )} */}

      {/* 이후 - 판례 요약 접어두기 기능 구현*/}
      {/* {!showCompare && !isContentVisible && (
        <> */}
      <HeightInterval height={20} />
      <CaseSummary
        question={question}
        detail={detail}
        answer={answer}></CaseSummary>
      <HeightInterval height={showCompare || isComparison ? 36 : 20} />
      <HrStyles />
      {/* </>
      )} */}

      {/* {true ? ( */}
      {showCompare || isComparison ? (
        <>
          {/* Toogle Hr 추가 필요 */}
          <CaseComparison
            caseId={caseId}
            isComparison={isComparison}
            commonality={commonality}
            difference={difference}
            setLoading={setIsLoading} // 로딩 상태 전달
            onCopy={handleCopy} // handleCopy 함수를 전달
            onSearch={handleSearch} // handleSearch 함수를 전달
          />
        </>
      ) : (
        <>
          <HrStyles />
          <Bottom>
            <Button
              image_src={copy}
              alt_text="복사하기"
              imgWidth={32}
              imgHeight={32}
              onClick={handleCopy}></Button>
            <Button
              image_src={search}
              alt_text="원문검색"
              imgWidth={32}
              imgHeight={32}
              onClick={handleSearch}></Button>
            <WrapperTB>
              <TooltipComponent />
            </WrapperTB>
            <ButtonCompare onClick={handleOpenModal}>
              내 상황과 비교하기
            </ButtonCompare>
          </Bottom>
        </>
      )}
      <ModalPayCheck
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleConfirmModal}
        law={2}
        buttonType="comparisonButton"
        headerContent="내 상황과 비교하기"
      />
    </WrapperMobile>
  );
};
/* CaseSummary 가로 세로 비 */
/*  width 비율 = 11% 81% 8% */
/*  height 비율 = 17% 68% 15% */

/* Header 가로 비 */
/* width 비율 = Number : CaseTtile : CaseSummary =  11% : 77% : 12% */

const WrapperMobile = styled.div`
  ${gradientBorder}

  height: auto;
  flex-shrink: 0;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  position: relative; /* 자식 Overlay 의 absolute를 위함 */

  @media (max-width: 1140px) and (min-width: 745px) {
    width: 680px;
  }

  @media (max-width: 744px) and (min-width: 481px) {
    width: auto;
    margin: 0px 32px;
  }

  @media (max-width: 480px) {
    width: auto;
    // margin: 0px 24px; // 부모 컴포넌트가 24px 좌우 padding을 이미 적용하고 있어서 여기서는 안 해도 됨
  }
`;

const Header = styled.div`
  min-height: 72px;
  max-height: 92px;
  margin-top: 16px;
  margin-bottom: ${({ caseTitleHeight }) =>
    caseTitleHeight >= 60 ? "16px" : "8px"};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TitleAndNumber = styled.div`
  display: flex;
`;

const Number = styled.div`
  width: auto;
  margin-left: 18px;
  margin-right: 18px;

  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

const CaseTitle = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const Relevance = styled.div`
  min-width: 66px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 4px; /* 관련도의 margin-right가 내용들과 일직선 되도록 */
  margin-left: auto;
`;

const Bottom = styled.div`
  height: 72px;
  display: flex;
  //justify-content: center;
  align-items: center;
  margin: 0px 18px;
  gap: 4px;
`;

const ButtonCompare = styled.div`
  height: 40px;
  flex-grow: 1;
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(92deg, #00f370 0%, #00badc 50.5%, #0057ff 100%);
  border: none;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  // margin-left: auto; /* 원문찾기 버튼과 비교하기 버튼 사이 간격 22px 고정 */
  // margin-right: 18px; /* 비교하기 버튼 margin-right 18px로 고정 */

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  @media (max-width: 744px) and (min-width: 481px) {
    margin-left: 0px;
  }

  @media (max-width: 480px) {
    margin-left: 0px;
  }
`;

const HrStyles = styled.hr`
  position: relative;
  width: auto;
  height: 0px;
  flex-shrink: 0;
  border: 1px solid #f1f1f1;
  border-top: 0.5px rgba(0, 0, 0, 0.1);
  margin: 0; /* 상하 마진 제거 */
`;

const WrapperTB = styled.div`
  display: flex;
  gap: 6px;
`;

/* Toggle Button 관련 */
const Overlay = styled.div`
  position: absolute;
  top: 11%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* 펼쳐지는 Content 보다 값이 커야함 */
`;

const ToggleButton = styled.button`
  width: 149px;
  height: 32px;
  border-radius: 24px;
  border: 1px solid #f1f1f1;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToggleText = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 500;
  margin-right: 4px;
`;

export default ResultFormMobile;
